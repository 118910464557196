/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { JsonLd, NormInfo, ServiceSteps } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom051: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-141-SSA1/SCFI-2012 - Cosméticos';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org", "@type": "WebPage", "@id":
              "https://www.cecsatrade.mx/nom-141-ssa1-scfi-2012", "url":
              "https://www.cecsatrade.mx/nom-141-ssa1-scfi-2012", "name":
              "NOM-141-SSA1/SCFI-2012", "description": `Esta Norma establece los requisitos
  de información sanitaria y comercial que debe ostentar la etiqueta en
  productos cosméticos de cualquier capacidad preenvasados y destinados al
  consumidor final.`, "isPartOf": {
              "@id":
                "https://www.cecsatrade.mx/#/schema/WebSite/1"
            }, "datePublished":
              "2023-01-31T08:08:40-06:00", "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": { "@id": "https://www.cecsatrade.mx/assets/141.svg" }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="Esta Norma establece los requisitos de información sanitaria y comercial
              que debe ostentar la etiqueta en productos cosméticos de cualquier
              capacidad preenvasados y destinados al consumidor final."
        />
      </Helmet>
      <section className='norm-info'>
        <h1 className='norm-info__title'>NOM-141-SSA1/SCFI-2012</h1>
        <h2>Etiquetado para productos cosméticos
          preenvasados. Etiquetado sanitario y comercial.</h2>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text'>
            <h2>Objetivo y campo de aplicación</h2>
            <p>
              Esta Norma establece los requisitos de información sanitaria y comercial
              que debe ostentar la etiqueta en productos cosméticos de cualquier
              capacidad preenvasados y destinados al consumidor final.
            </p>
          </div>
          <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-141.svg" alt="NOM 141 Logo" />
        </div>
        <div className="norm-info__container__image-container">
          <div>
            <p className="norm-info__container__text">
              <h2
                className="norm-info__container__sub-title"
                id="requisitos-de-etiquetado"
              >Requisitos de etiquetado para los cosméticos preenvasados</h2>
              <ul>
                <li className="list-group-item">■ Presentación de la información: Los productos destinados a ser
                  comercializados en México, deben ostentar una etiqueta con la
                  información de esta norma en idioma español. Aunque también pueda
                  estar en otros idiomas. Debe cuidar que los caracteres sean
                  claros, visibles, indelebles y en colores contrastantes. Deben ser
                  fáciles de leer por el consumido en circunstancias normales de
                  compra y uso.</li>
                <li className="list-group-item">■ La información que se presente al consumidor, debe ser veraz y
                  comprobable.</li>
                <li className="list-group-item">■ Las etiquetas, deben fijarse de manera tal que permanezcan
                  disponibles hasta el momento de su compra y uso en condiciones
                  normales.</li>
                <li className="list-group-item">■ Denominación genérica y específica del producto: Todos los
                  productos deben ostentar la denominación genérica, conforme a lo
                  que se establece en el apéndice informativo “A” de esta norma. En
                  caso de productos cuya denominación no se encuentre dentro de este
                  listado, su denominación será aquella que mejor los describa.
                  Podrá también usarse una ilustración o viñeta que representa el
                  uso del producto.</li>
              </ul>
            </p>
          </div>
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/141-image-1.svg"
            alt="NOM Logo"
          />
        </div>
        <div className="norm-info__container__image-container">
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/141-image-2.svg"
            alt="NOM Logo"
          />
          <div>
            <p className="norm-info__container__text">
              <ul>
                <li>Identificación del responsable del producto: Este dato debe
                  figurar en la superficie de información del envase primario
                  incluyendo:</li>
              </ul>
              <ul className="norm-info__container__ul-2 list-group list-group-numbered list-group-flush">
                <li className="list-group-item">El nombre</li>
                <li className="list-group-item">Denominación</li>
                <li className="list-group-item">Razón social</li>
                <li className="list-group-item">Domicilio del responsable del producto</li>
              </ul>
              <br />
              Tratándose de productos importados, estos datos podrán incorporarse al
              producto, en el Territorio Nacional después del despacho aduanero y
              antes de su comercialización.
              <ul className='list-group list-group-numbered list-group-flush'>
                <li className="list-group-item">Leyenda del país de origen: por ejemplo «Producto de …», «Hecho
                  en…», «Manufacturado en …» u otras análogas.</li>
                <li className="list-group-item">Declaración de lote.</li>
                <li className="list-group-item">Instrucciones o modo de uso.</li>
                <li className="list-group-item">Declaraciones prohibidas de propiedades.</li>
              </ul>
            </p>
          </div>
        </div>
        <p className="norm-info__container__text">
          <ul className='list-group list-group-numbered list-group-flush'>
            <li>Se prohíbe el uso de las siguientes declaraciones:</li>
            <li className="list-group-item">Declaración de propiedades que no pueden comprobarse.</li>
            <li className="list-group-item">No podrán atribuirse a los cosméticos, acciones propias de los
              medicamentos.</li>
            <li className="list-group-item">En la comercialización de los productos cosméticos, el etiquetado no
              utilizará ningún recurso visual en el empaque con el fin de atribuir a
              estos productos características de las que carezca.</li>
            <li className="list-group-item">En los envases múltiples o colectivos será necesario declarar
              únicamente, la información de etiquetado que no contengan los
              productos, de forma individual. Para los productos que se
              comercialicen en envases múltiples o colectivos:</li>
          </ul>
          <ul className="norm-info__container__ul-2">
            <li className="list-group-item">■ La declaración de la cantidad puede expresarse indistintamente por
              cuenta numérica por los envases que contiene o por contenido neto,
              excepto cuando el contenido o contenido neto sea obvio, no siendo
              restrictivo la ubicación y tamaño de la letra utilizada.</li>
            <li className="list-group-item">■ Los envases individuales deben contener la información completa que
              establece este ordenamiento, la declaración de cantidad del dato
              cuantitativo de acuerdo con lo establecido en este ordenamiento. En el
              caso de que los envases individuales no contengan la declaración de
              cantidad, ésta debe declararse en el envase múltiple o colectivo, no
              siendo restrictivo la ubicación y tamaño de la letra utilizada.</li>
          </ul>
          <br />Información Comercial según la NOM-141-SSA1/SCFI-2012
          <ul className='list-group list-group-numbered list-group-flush'>
            <li className="list-group-item">Se debe cumplir con lo que establece la
              <a
                href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002#gsc.tab=0"
              > Norma Oficial Mexicana NOM-008-SCFI-2002 Sistema General de Unidades
                de Medida</a>, sin perjuicio de que además se puedan utilizar
              unidades de medida de otro sistema.</li>
            <li className="list-group-item">Para la declaración de cantidad deben ostentar el dato cuantitativo,
              seguido de la unidad de magnitud correspondiente. De esta manera no es
              necesario ostentar las leyendas «CONTENIDO», «CONTENIDO NETO», O SUS
              ABREVIATURAS, «CONT», «CONT.NET».</li>
            <li className="list-group-item">La declaración anterior debe aparecer en la superficie principal de
              exhibición.</li>
            <li className="list-group-item">El tamaño de la declaración de contenido o contenido neto debe ser
              de acuerdo a lo establecido en la
              <a
                href="https://www.dof.gob.mx/nota_detalle.php?codigo=4936602&fecha=06/11/2006#gsc.tab=0"
              > Norma Oficial Mexicana NOM-030-SCFI-2006</a></li>
          </ul>
          En las muestras o ayudas de venta y amenidades debe figurar la siguiente
          información:
          <ul className="norm-info__container__ul-2 list-group list-group-numbered list-group-flush">
            <li className="list-group-item">Denominación del producto</li>
            <li className="list-group-item">Nombre del responsable del producto</li>
            <li className="list-group-item">Número de lote</li>
            <li className="list-group-item">Las instrucciones de uso y/o leyendas precautorias.</li>
          </ul>
          En las muestras o ayudas de venta de lociones y fragancias cuyo contenido
          neto sea menor o igual a 2 ml debe figurar en el envase secundario al
          menos:
          <ul className="norm-info__container__ul-2 list-group list-group-numbered list-group-flush">
            <li className="list-group-item">La marca comercial</li>
            <li className="list-group-item">La denominación del producto, para los productos importados podrá
              ser la del país de origen.</li>
            <li className="list-group-item">La razón social del fabricante del mismo.</li>
            <li className="list-group-item">Leyendas precautorias.</li>
          </ul>
          <br />Información Sanitaria según la NOM-141-SSA1/SCFI-2012 En los
          productos objeto de esta norma, deben figurar una lista de nombres e
          ingredientes a excepción de los perfumes y fragancias. La fórmula debe ir
          precedida por el término «ingredientes» y enlistarse por orden
          cuantitativo decreciente de la siguiente manera.
          <ul className='list-group list-group-numbered list-group-flush'>
            <li className="list-group-item">Por orden cuantitativo decreciente aquellos ingredientes cuya
              concentración sea superior al 1%. Seguido de estos, por aquellos
              ingredientes en concentración inferior o igual al 1%.</li>
            <li className="list-group-item">Para la nomenclatura de los ingredientes, puede emplearse cualquier
              nombre establecido en los Acuerdos, el nombre químico o el nombre tal
              cual como aparece en la
              <a
                href="https://es.wikipedia.org/wiki/Nomenclatura_Internacional_de_Ingredientes_Cosm%C3%A9ticos"
              > Nomenclatura Internacional de Ingredientes Cosméticos (INCI).</a></li>
            <li className="list-group-item">Las fragancias y sabores pueden designarse con el nombre genérico.</li>
            <li className="list-group-item">Los materiales de origen botánico deben designarse con el nombre
              científico de la planta, siendo opcional su nombre común.</li>
            <li className="list-group-item">Los productos con una o más presentaciones, en los que la fórmula
              base es la misma y sólo varía el uso de los colorantes. Se incluirá la
              lista con los nombres de los ingredientes comunes de la fórmula,
              seguida de otra con todos los colorantes usados.</li>
            <li className="list-group-item">Para la declaración de los nombres de los ingredientes en aquellos
              productos que por su tamaño carecen de espacio, (como son lápices de
              cejas, delineadores, entre otros) ésta podrá figurar en el envase
              secundario si lo hubiere o bien en un volante impreso anexo al
              producto o en una etiqueta de bandera.</li>
            <li className="list-group-item">En productos con una duración menor o igual a 24 meses debe figurar,
              en el envase primario o secundario, la fecha hasta la cual un producto
              es seguro para la salud del consumidor. Se debe indicar mínimo mes y
              el año. Este dato podrá ir precedido por la leyenda, a elección del
              fabricante: Caducidad, Consumo preferente, u otras.</li>
          </ul>
        </p>
        <NormInfo
          DOFDate='19/09/2012'
          VigorDate='18/12/2012'
          responsible='SECRETARIA DE SALUD y SECRETARIA DE ECONOMIA'
        />
        <div className="norm-info__container__image-container">
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/141-image-3.svg"
            alt="NOM Logo"
          />
          <div>
            <p className="norm-info__container__text">
              <h2 className="norm-info__container__sub-title">Excepciones en la
                declaración de fecha</h2><br />
              Quedan exceptuados de la declaración de esta fecha, los productos que
              no permiten el crecimiento microbiano o que tienen una alta rotación
              de venta y uso como: Aceites, Jabones sólidos, sales de baño, perfumes
              y derivados, desodorantes que no sean emulsiones, antitranspirantes,
              depilatorios, tintes y decolorantes, shampoo, acondicionadores,
              permanentes, relajantes permanentes de rizos y alisados permanentes,
              fijadores, oxidantes, productos para uñas, brillantes, unidosis y
              productos en envases presurizados.
            </p>
          </div>
        </div>
        <p className="norm-info__container__text">
          <h2
            className="norm-info__container__sub-title"
            id="referencias"
          >Referencias</h2>
          Esta Norma se complementa con las siguientes Normas Oficiales Mexicanas
          vigentes o las que las sustituyan:
          Norma Oficial Mexicana
          <a
            href="https://dof.gob.mx/nota_detalle.php?codigo=4792407&fecha=13/10/1993"
          > NOM-002-SCFI-1993</a>, productos preenvasados-contenido neto
          tolerancias y métodos de verificación.
          <br />Norma Oficial Mexicana
          <a
            href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002#gsc.tab=0"
          > NOM-008-SCFI-2002</a>, Sistema General de Unidades de Medida.
          <br />Norma Oficial Mexicana
          <a
            href="https://www.dof.gob.mx/nota_detalle.php?codigo=4936602&fecha=06/11/2006#gsc.tab=0"
          > NOM-030-SCFI-2006</a>, Información comercial-Declaración de cantidad
          en la etiqueta. Especificaciones.
        </p>
        <ServiceSteps
          withNewsLetter={false}
        />
      </section>
    </>
  )
}

export default Nom051