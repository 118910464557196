/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { JsonLd, NormInfo, ServiceSteps } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom055: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-235-SE-2020, Atún y bonita preenvasados-Denominación-Especificaciones-Información comercial y ymétodos de prueba';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "https://www.cecsatrade.mx/nom-235-se-2020",
            "url": "https://www.cecsatrade.mx/nom-235-se-2020",
            "name": "NOM-235-SE-2020",
            "description": "La presente Norma Oficial Mexicana tiene por objeto establecer los requisitos de información sanitaria y generales que para la venta y suministro de las pinturas, tintas, barnices, lacas y esmaltes, debe satisfacer el etiquetado de sus envases.",
            "isPartOf": {
              "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1"
            },
            "datePublished": "2023-08-17T08:08:40-06:00",
            "dateModified": "2023-08-17T08:43:03-06:00",
            "primaryImageOfPage": {
              "@id": "https://www.cecsatrade.mx/assets/235.svg"
            }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="La presente Norma Oficial Mexicana establece la denominación de atún o bonito, con o sin ingredientes opcionales, en cualquiera de sus formas de presentación, envasados en recipientes de cierre hermético, así como la información comercial, especificaciones y métodos de prueba. Esta Norma es aplicable a los productos de fabricación nacional o extranjera que se comercialicen en el territorio nacional."
        />
      </Helmet>
      <section className='norm-info'>
        <h1 className='norm-info__title'>NOM-235-SE-2020</h1>
        <h2>Atún y bonita preenvasados-Denominación-Especificaciones-Información comercial y ymétodos de prueba.</h2>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text'>
            <h2>Objetivo y campo de aplicación</h2>
            <p>
              La presente Norma Oficial Mexicana establece la denominación de atún o bonito, con o sin ingredientes opcionales, en cualquiera de sus formas de presentación, envasados en recipientes de cierre hermético, así como la información comercial, especificaciones y métodos de prueba. Esta Norma es aplicable a los productos de fabricación nacional o extranjera que se comercialicen en el territorio nacional.
            </p>
          </div>
          <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-235.svg" alt="NOM 235 Logo" />
        </div>
        <div className="norm-info__container__image-container">
          <div>
            <p className="norm-info__container__text">
              <h2
                className="norm-info__container__sub-title"
                id="definiciones"
              >Definiciones</h2>
              <dl>
                <dt>Atún o bonita preenvasados</dt>
                <dd>Productos elaborados con la carne procedente de cualquiera de las especies de atún o bonita y que se encuentran descritas en la Tabla 1 del numeral </dd>

                <dt>Caldo vegetal</dt>
                <dd>El caldo que se prepara a partir de extractos de uno o más de los siguientes vegetales o leguminosas: ajo, apio, cebolla, chícharo, col, espinaca, soya, lenteja, papa, perejil, pimiento, tomate, zanahoria, entre otros. </dd>

                <dt>Consumidor o consumidor final</dt>
                <dd>Persona física o moral que adquiere o que disfruta como destinatario final los productos de atún o bonita preenvasados.</dd>

                <dt>Contenido neto</dt>
                <dd>Cantidad de atún o bonita preenvasados que permanece después de que se han hecho todas las deducciones de tara.</dd>

              </dl>
            </p>
          </div>
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/235-image-1.svg"
            alt="NOM Logo"
          />
        </div>
        <div className="norm-info__container__image-container">
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/235-image-2.svg"
            alt="NOM Logo"
          />
          <div>
            <p className="norm-info__container__text">
              <dl>
                <dt>Embalaje</dt>
                <dd>Material que envuelve, contiene y protege los productos de atún o bonita preenvasados, para efectos de su almacenamiento y transporte. </dd>

                <dt>Envase</dt>
                <dd>Cualquier recipiente, o envoltura en el cual está contenido el atún o bonita para su venta al consumidor. </dd>

                <dt>Etiqueta</dt>
                <dd>Cualquier rótulo, marbete, inscripción, imagen u otra materia descriptiva o gráfica, escrita, impresa, estarcida, marcada, grabada en alto o bajo relieve, adherida, sobrepuesta o fijada al envase de los productos atún o bonita preenvasados. </dd>

                <dt>Masa drenada</dt>
                <dd>Cantidad de atún o bonita e ingredientes opcionales que representa el contenido de un envase después de que el líquido ha sido removido por un método prescrito.</dd>

                <dt>Medio de cobertura</dt>
                <dd>Es aquel líquido, que ha sido adicionado al atún o bonita preenvasados en recipientes de cierre hermético y sometidos a tratamiento térmico dentro de su envase.</dd>

                <dt>País de origen</dt>
                <dd>Es aquél en el cual el atún o bonita preenvasado fue fabricado, manufacturado o producido. </dd>

              </dl>
            </p>
          </div>
        </div>
        <p className="norm-info__container__text">
          <dl>

            <dt>Responsable del producto</dt>
            <dd>Persona física o moral que importe o que elabore atún o bonita preenvasados o que haya ordenado su elaboración total o parcial a un tercero.</dd>

            <dt>Símbolos y términos abreviados</dt>
            <dd>
              <b>cm</b> - Centímetro
              <br /><b>m/m</b> - Masa sobre masa
              <br /><b>mg</b> - Miligramo
              <br /><b>g</b> - Gramo
              <br /><b>%</b> - Por ciento</dd>

          </dl>
        </p>
        <NormInfo
          DOFDate='18/09/2020'
          VigorDate='01/04/2021'
          responsible='SECRETARÍA DE ECONOMIA'
        />
        <p className="norm-info__container__text">
          <h2
            className="norm-info__container__sub-title"
            id="referencias"
          >Referencias</h2>
          Esta Norma Oficial Mexicana se complementa con las siguientes Normas Oficiales Mexicanas y Normas Mexicanas vigentes, <b>NOM-002-SCFI-2011</b> Productos preenvasados-Contenido neto-Tolerancias y métodos de verificación, publicada en el Diario Oficial de la Federación el 10 de agosto de 2012. <b>NOM-008-SCFI-2002</b> Sistema general de unidades de medida, publicada en el Diario Oficial de la Federación el 27 de noviembre de 2002. <b>NOM-030-SCFI-2006</b> Información comercial-Declaración de cantidad en la etiqueta-Especificaciones, publicada en el Diario Oficial de la Federación el 6 de noviembre de 2006. <b>NOM-051-SCFI/SSA1-2010</b> Especificaciones generales de etiquetado para alimentos y bebidas no alcohólicas preenvasados-Información comercial y sanitaria, publicada en el Diario Oficial de la Federación el 5 de abril de 2010. <b>NOM-025-STPS-2008</b> Condiciones de iluminación en los centros de trabajo, publicada en el Diario Oficial de la Federación el 30 de diciembre de 2008. <b>NMX-F-315-1978</b> Determinación de la masa drenada o escurrida en alimentos envasados, declaratoria de vigencia publicada en el Diario Oficial de la Federación el 23 de febrero de 1978. Acuerdo Acuerdo por el que se determinan los aditivos y coadyuvantes en alimentos, bebidas y suplementos alimenticios, su uso y disposiciones sanitarias, publicado en el Diario Oficial de la Federación el 16 de julio de 2012.
        </p>
        <ServiceSteps
          withNewsLetter={false}
        />
      </section>
    </>
  )
}

export default Nom055