/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { BenefictsAndWhoDifferent, ExcusiveAttention, JsonLd, NormInfo, References, ServiceSteps, ServicesToOneClick } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom020: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-020-SCFI-1997 - Productos de cuero';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org", "@type": "WebPage", "@id":
              "https://www.cecsatrade.mx/nom-020-scfi-1997", "url":
              "https://www.cecsatrade.mx/nom-020-scfi-1997", "name": "NOM-020-SCFI-1997",
            "description": `La NOM-020-SCFI-1997. Información comercial del etiquetado de cueros y pieles curtidas naturales,calzado, marroquinería y los productos elaborados total o parcialmente con dichos materiales, se publicó el 04 de septiembre de 1997 en el Diario Oficial de la Federación pero entró en vigor 180 días después el 19 de noviembre de 1998`, "isPartOf": {
              "@id":
                "https://www.cecsatrade.mx/#/schema/WebSite/1"
            }, "datePublished":
              "2023-01-31T08:08:40-06:00", "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": { "@id": "https://www.cecsatrade.mx/assets/020.svg" }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="La NOM-020-SCFI-1997. Información comercial del etiquetado de cueros y pieles curtidas naturales,calzado, marroquinería y los productos elaborados total o parcialmente con dichos materiales, se publicó el 04 de septiembre de 1997 en el Diario Oficial de la Federación pero entró en vigor 180 días después el 19 de noviembre de 1998"
        />
      </Helmet>
      <section className='norm-info'>
        <div className='sub-header'>
          <div className='sub-header__spacer'></div>
          <a className='sub-header__button' href="https://blog.cecsatrade.mx/" target='_blank' rel="noreferrer">Blog</a>
          <a
            className='sub-header__email'
            href="mailto:asesoria@cecsatrade.mx"
            target="_blank">
            <img loading="lazy" src="/img/mail.svg" alt="Mail" />
            |
            Mail de CECSA
          </a>
        </div>
        <h1 className='norm-info__title'>NOM-020-SCFI-1997 PIELES, CUEROS Y CALZADO</h1>
        <div className='norm-info__header norm-info__header--020'>
          <button onClick={() => {
            const element = document.getElementById("benefits");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
          }}>Beneficios</button>
          <button onClick={() => {
            const element = document.getElementById("preguntas-frecuentes");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
            }
          }}>Preguntas frecuentes</button>
        </div>
        <div className='norm-info__image-container'>
          {/* <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-003-asset.svg" alt="NOM 003 Logo" /> */}
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Sé el primero en saber...</h2>
            <br />
            <p>
              La <b>norma de cueros y pieles curtidas naturales, materiales sintéticos o artficiales, calzado, marroquenería y productos elaborados de manera total o parcial con dichos materiales</b> tiene como objeto estblecer la información comercial que debe contener el etiquetado de estos para su industrialización o comercialización dentro del territorio mexicano.
              <br />
              <br />
              Esta <b>Norma Oficial Mexicana</b> es aplicable a los requisitos para garantizar un correcto <b>etiquetado de pieles, cueros y sintéticos</b> en el territorio nacional para personas físicas y morales que se dedican al proceso o importación de estos productos.
            </p>
          </div>
        </div>
        <div className='norm-info__image-container norm-info__image-container--black'>
          <div className='norm-info__image-container__img-container'>
            <img className='norm-info__image-container__img-container__image' loading="lazy" src="/img/cueros.png" alt="cueros" />
          </div>
          <div className='norm-info__image-container__text norm-info__image-container__text--center norm-info__image-container__text--small-margin'>
            <p style={{ textAlign: 'left' }}>
              La NOM-020-SCFI-1997 regula las especificaciones de etiquetado, calidad y autenticidad de pieles, cueros y productos de calzado en México, asegurando la protección del consumidor y la transparencia en la comercialización de estos productos.
            </p>
          </div>
        </div>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Entrada en vigor</h2>
            <br />
            <p>
              La <b>NOM-020-SCFI-1997. Información comercial del etiquetado de cueros y pieles curtidas naturales,calzado, marroquinería y los productos elaborados total o parcialmente con dichos materiales</b>, se publicó el 04 de septiembre de 1997 en el Diario Oficial de la Federación pero entró en vigor 180 días después el 19 de noviembre de 1998.
            </p>
            <br />
            <a className='norm-info__image-container__anchorage' href="https://dof.gob.mx/nota_detalle.php?codigo=4875625&fecha=27/04/1998#gsc.tab=0" target='_blank'>Más información de esta NOM</a>
          </div>
        </div>
        <img className='norm-info__ellipse' src="/img/ellipse-black.svg" alt="ellipse" />
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Aplicación</h2>
            <br />
            <p>
              Esta norma es aplicable a todos los <b>productos nacionales</b> y de procedencia extranjera  destinados a comercialización.
              <br />
              <br />
              La <b>información comercial</b> a que se refiere esta Norma debe indicarse en los <b>cueros y pieles curtidas naturales, materiales sintéticos o artificiales con esa apariencia, calzado, marroquinería</b> o de cualquier otro origen. Estableciendo asi el correcto etiquetado  para su comercialización nacional.
              <br />
              <div className='norm-info__image-container__aplications-020'>
                <div className='norm-info__image-container__aplications-020__item'>
                  <img loading="lazy" src="/img/calzado.svg" alt="calzado" />
                  <span><b>Calzado de piel y cuero</b></span>
                </div>
                <div className='norm-info__image-container__aplications-020__item'>
                  <img loading="lazy" src="/img/cinturones.svg" alt="cinturones" />
                  <span><b>Cinturones y correas de piel</b></span>
                </div>
                <div className='norm-info__image-container__aplications-020__item'>
                  <img loading="lazy" src="/img/marroquinería.svg" alt="marroquinería" />
                  <span><b>Articulos de marroquinería</b></span>
                </div>
                <div className='norm-info__image-container__aplications-020__item'>
                  <img loading="lazy" src="/img/accesorios.svg" alt="accesorios" />
                  <span><b>Accesorios de piel</b></span>
                </div>
              </div>
            </p>
          </div>
        </div>
        <br />
        <ExcusiveAttention />
        <div className='norm-info__image-container norm-info__image-container--blue-050'>
          <img className='norm-info__image-container__image-background-1' loading="lazy" src="/img/background-circle-blue-1.svg" alt="backghround 1" />
          <div className='norm-info__image-container__text'>
            <h2>La NOM-020 no aplica a:</h2>
            <br />
            <p>Estos productos son algunos de los que están excluidos de la norma porque están sujetos a regulaciones más específicas que se ajustan a sus características y riesgos particulares</p>
            <div className='norm-info__image-container__text__data-item-container'>
              <div className='norm-info__image-container__text__data-item norm-info__image-container__text__data-item--twin-vertical'>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/189-cosmeticos.svg" alt="cosmeticos" />
                <span>Productos de textil</span>
              </div>
              <div className='norm-info__image-container__text__data-item norm-info__image-container__text__data-item--twin-vertical'>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/189-industrial.svg" alt="industrial" />
                <span>Papel</span>
              </div>
            </div>
            <div className='norm-info__image-container__text__data-item-container'>
              <div className='norm-info__image-container__text__data-item norm-info__image-container__text__data-item--twin-vertical'>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/189-plaguicidas.svg" alt="plaguicidas" />
                <span>Mobiliario de madera</span>
              </div>
            </div>
          </div>
          <img className='norm-info__image-container__image-background-2' loading="lazy" src="/img/background-circle-blue-2.svg" alt="backghround 2" />
        </div>
        <div className='norm-info__image-container norm-info__image-container--black norm-info__image-container--margin norm-info__image-container--050' style={{ paddingTop: '30rem', marginBottom: '30rem', overflow: 'visible', zIndex: '7' }}>
          <img className='norm-info__image-container__jabon-189' loading="lazy" src="/img/pieles.png" alt="pieles" style={{ left: '4rem', height: '50rem' }} />
          <h2>DEFINICIONES</h2>
          <div className="norm-info__050-definitions__item">
            <span>Acabado</span>
            <p>Se entiende por acabado el proceso que se le da a una piel curtida para cambiar su aspecto original. </p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Materiales sintéticos o artificiales</span>
            <p>Se entiende por acabado el proceso que se le da a una piel curtida para cambiar su aspecto original.
              <br /><br />Para los materiales plásticos, sintéticos o textiles con apariencia de piel o cuero, pueden denominarse como tales. <br /><br />a) Corte de calzado.- La parte superior del mismo (upper). <br /><br />b) Fabricante.- Es la persona física o moral responsable de la transformación de un producto. <br /><br />c) Lado.- El corte longitudinal por línea media, que divide una piel de animal en dos partes. <br /><br />d) Marcado.- El proceso de troquelar, grabar, imprimir, sellar o moldear en forma permanente. <br /><br />e) Marroquinería.- La elaboración de artículos de piel, de materiales textiles u otro material natural, sintético o artificial como cinturones, bolsas, billeteras, mochilas, equipaje, portafolios, etc. <br /><br />f) Permanente.- Es la conservación del marcado o etiquetado hasta que el producto sea adquirido por el consumidor final.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Otros materiales o componentes</span>
            <p>Son otros materiales utilizados para la manufactura, que deben ser especificados por su nombre genérico como hule, cartón, madera, textil, ixtle, metal y otros.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Piel o cuero</span>
            <p>Material proteico fibroso con flor (colágeno), que cubre al animal y que ha sido tratado químicamente con material curtiente para hacerlo estable bajo condiciones húmedas.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Carnaza</span>
            <p>Es la parte obtenida, del lado de la carne, al dividir las pieles en una o varias capas, cualquiera que sea su acabado. La carnaza puede ser obtenida de cualquier animal y todos los artículos elaborados con este material.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Materiales reciclados</span>
            <p>Son los que han sufrido un proceso físico o químico de aglutinado, pegado, prensado, etc., hechos con fibras naturales, debiendo manifestarse como materiales reciclados, optativamente puede especificarse el material constitutivo (fibra de cuero, fibra de poliamida (nylon), poliéster y otros).</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Otras pieles</span>
            <p>Las pieles y cueros curtidos de otros animales que no han sido mencionados se determinan o denominan, por el nombre específico o común del animal de que procedan.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Piel de animal bovino o vacuno</span>
            <p>Material proteico fibroso con flor (colágeno), que cubre al animal y que ha sido tratado químicamente con material curtiente para hacerlo estable bajo condiciones húmedas; produciéndose además otros cambios asociados, tales como características físicas mejoradas, estabilidad hidrotérmica y flexibilidad.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Piel de animal caprino</span>
            <p>a) Cabra.- Es la piel que proviene de animales de esta especie de más de un mes de nacido y cuya superficie total es mayor de 24 dm2. <br />b) Cabrito.- Es la piel que proviene de esta especie hasta de un mes de nacido, cuya superficie es de hasta 24 dm2.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Piel de animal equino</span>
            <p>a) Anca de potro.- Es la piel que corresponde a la región del anca de esta especie. Este término puede utilizarse opcionalmente ya que expresa la zona y calidad del producto y puede sustituir al término "piel de equino".</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Piel de animal ovino</span>
            <p>a) Borrego.- Es la piel del animal de esta especie mayor de un año de edad. <br />b) Cordero.- Es la piel del animal de esta especie no mayor de un año de edad.Piel de animal marinoLa que pertenece a las especies de animales marinos como tiburón, raya, foca, morsa, tortuga, anguila, pescado, etc.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Piel de animal marino</span>
            <p>La que pertenece a las especies de animales marinos como tiburón, raya, foca, morsa, tortuga, anguila, pescado, etc.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Piel de animal porcino</span>
            <p>La que proviene de animales de esta especie, tales como el cerdo, jabalí, pécari y otros.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Piel de reptil</span>
            <p>La que proviene de animales como víbora o serpiente, iguana, lagarto, cocodrilo, etc.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Suela de cuero</span>
            <p>Es el cuero curtido para suela, este artículo debe denominarse invariablemente suela de cuero. Pudiendo además especificarse la especie de la cual proceda.</p>
          </div>
          <img className='norm-info__050-definitions__footer' loading="lazy" src="/img/definitions-050-footer.svg" alt="footer" />
        </div>
        <BenefictsAndWhoDifferent title={'CERTIFICA TUS PRODUCTOS'} text={'Contactanos, nosotros te apoyamos en cada paso que debes realizar para cumplir conforme lo establecido de manera nacional en materia de comercial dentro de la industria.'} />
        <References
          items={[
            (
              <li>
                <img src="/img/list-item-020.png" alt="cinturón" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002#gsc.tab=0" target='_blank'>NOM-008-SCFI-2002</a>,  (Sistema General de Unidades de Medida) es una NOM que define las unidades, símbolos y reglas de escritura para el Sistema Internacional de Unidades (SI) y otras unidades aceptadas por la CGPM que están fuera de este sistema. Se aplica en diversos campos, incluyendo la ciencia, la tecnología, la industria, la educación y el comercio, y establece el marco general para el uso adecuado del Sistema General de Unidades de Medida.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-020.png" alt="cinturón" />
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5415883&fecha=18/11/2015#gsc.tab=0" target='_blank'>NOM-008-SCFI-2002</a>NMX-Z-013-SCFI-2015 (Guía para la Estructuración y Redacción de Normas) Establece reglas para la redacción y estructuración de Proyectos de Normas Mexicanas.
              </li>
            )
          ]}
        />
        <NormInfo
          DOFDate='04/09/1997'
          VigorDate='19/03/1998'
          responsible='SECRETARIA DE ECONOMIA '
        />
        <div className='norm-info__questions' id='preguntas-frecuentes'>
          <h2 className="norm-info__container__sub-title">Preguntas frecuentes</h2>
          <ul className="accordion norm-info__container__not-transform-ul" id="accordion">
            <li className="accordion-item">
              <div id="heading1">
                <button className="accordion-button norm-info__container__accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                  ¿Cuáles son los requisitos de etiquetado y información comercial establece la NOM-020-SCFI-1997?
                </button>
              </div>
              <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Los productos deben tener los siguientes requisitos en el etiquetado: <br />a) Nombre, denominación o razón social del fabricante nacional o importador o marca registrada. <br />b) La leyenda "Hecho en México" o el nombre del país de origen. <br />c) Lista de los materiales con los que se haya elaborado. <br />d) Que la etiqueta se encuentre en español
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                  ¿Esta norma se aplica para productos nacionales o importados?
                </button>
              </div>
              <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Esta norma es aplicable para ambos, se aplica tanto para productos nacionales como para los que son importados
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading3">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                  ¿Cuáles son las sanciones por no cumplir con la NOM-020-SCFI-1997?
                </button>
              </div>
              <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordion">
                <div className="accordion-body">
                  De acuerdo a la Ley  Federal de la Metrología y Normalización, en el articulo 112, nos dice que si un producto no cumple con la NOM puede generar multas, clausura temporal o definitiva, que podrá ser parcial o total, arresto hasta por treinta y seis horas, suspensión o revocación de la autorización, aprobación, o registro según corresponda y suspensión o cancelación del documento donde consten los resultados de la evaluación de la conformidad, así como de la autorización del uso de contraseñas y marcas registradas.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading4">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse4">
                  ¿Qué tipo de productos de cuero están exentos de esta norma?
                </button>
              </div>
              <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Los productos que estan excentos son aquellos que no cuentan con ningun tipo de material que aplica en la norma, ademas de productos que se les aplique otra norma vigente.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading5">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                  ¿Cómo se verifica el cumplimiento de la NOM-020-SCFI-1997?
                </button>
              </div>
              <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Para verificar el cumplimiento de esta norma te invitamos a acercarte a una unidad de inspección acreditada, con CECSA te brindamos el apoyo de revisar la información comercial de tu mercancía para proporcionarte el etiquetado correcto con base a esta normativa y asi al ser verificado el producto o algún elemento correspondiente de acuerdo a la norma, emtiendo como resultado final  un dictamen de cumplimiento para que tu mercancía teniendo en cuenta las fechas que sean indicadas  para  no tener problema alguno, si es el caso, una constancia de conformidad.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading5">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                  ¿Cómo se que tipo de piel tiene mi producto?
                </button>
              </div>
              <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordion">
                <div className="accordion-body">
                  De acuerdo al tipo de piel o material con el que este hecho el producto tendra una leyenda
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading6">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                  ¿Quién regula la NOM-020-SCFI-1997?
                </button>
              </div>
              <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Secretaria de Economia es quien se encarga de regular esta norma.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading8">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                  ¿Qué hago si detienen mi producto en aduana?
                </button>
              </div>
              <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordion">
                <div className="accordion-body">
                  En primer lugar, deberás de solicitar la fracción arancelaria del producto. Una vez que tengas la fracción arancelaria y corrobores que le solicitan el cumplimiento de la NOM-020-SCFI-1997, deberás de contactar a una Unidad de Inspección para obtener una Constancia de Inspección. <br />Debido a que el producto no puede etiquetarse en México, deberás de etiquetar en la aduana o regresar el producto con tu proveedor para que sea etiquetado. <br />Te recomendamos analizar los costos de cada situación y evaluarlos. <br /><br />Una vez que nos contactes, te brindaremos la asesoría y apoyo para que puedas liberar tu producto de la aduana. El proceso será rápido, cada minuto cuenta, por lo que te dejamos nuestro correo (contacto@cecsatrade.mx) y número de contacto (56 5019 0728)
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading9">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                  ¿Cómo puedo importar mi producto con esta NOM?
                </button>
              </div>
              <div id="collapse9" className="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Para importar a México un producto es necesario considerar un proceso detallado para asegurar que el producto cumpla con las regulaciones sanitarias y comerciales establecidas por esta norma. En lo cual de necesita identificar el producto a importar y identificar que este dentro de las regulaciones de la norma, también antes de importar, debes obtener un Certificado de Conformidad emitido por una Unidad de Verificación Acreditada (UVA), tener bien el etiquetado de acuerdo a lo establecido en la NOM, debes tener una correcta clasificación arancelaria y un pedimento de importación. Para después en aduana poder realizar el despacho de manera optima y ágil, también es importante mencionar el pago de impuestos al comercio exterior,  para después cuando se hayan liberado las mercancías ya puedas comercializarlos en territorio nacional.
                </div>
              </div>
            </li>
          </ul>
        </div>
        <img className='norm-info__ellipse' src="/img/ellipse-blue.svg" alt="ellipse" />
        <ServicesToOneClick />
        <ServiceSteps
          withNewsLetter={false}
        />
      </section>
    </>
  )
}

export default Nom020