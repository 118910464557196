/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { JsonLd, NormInfo, ServiceSteps } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom015: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-015-SCFI-2007 - Fuguetes';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org", "@type": "WebPage", "@id":
              "https://www.cecsatrade.mx/nom-015-scfi-2007", "url":
              "https://www.cecsatrade.mx/nom-015-scfi-2007", "name": "NOM-015-SCFI-2007",
            "description": `Esta Norma Oficial Mexicana es aplicable a todos los juguetes
            elaborados con cualquier material, inclusive operados con pila, baterías y/ o
            cualquier otra fuente de alimentación, que se comercialicen en el territorio
      de los Estados Unidos Mexicanos.`, "isPartOf": {
              "@id":
                "https://www.cecsatrade.mx/#/schema/WebSite/1"
            }, "datePublished":
              "2023-01-31T08:08:40-06:00", "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": { "@id": "https://www.cecsatrade.mx/assets/015.svg" }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="¿Qué dice la NOM-015-SCFI-2007? Establece la información comercial que deben mostrar los juguetes que se comercializan en México. Esta norma se aplica a todos los juguetes, independientemente de los materiales con los que estén hechos y si son operados por celdas, baterías u otras fuentes de alimentación."
        />
      </Helmet>
      <section className='norm-info'>
        <h1 className='norm-info__title'>NOM-015-SCFI-2007</h1>
        <h2>Información comercial - Etiquetado para
          juguetes, peluches y juegos de mesa.</h2>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text'>
            <h2>Objetivo y campo de aplicación</h2>
            <p>
              Esta Norma Oficial Mexicana tiene como objetivo establecer la información
              comercial que deben ostentar los juguetes que se comercialicen en el
              territorio de los Estados Unidos Mexicanos.
              <br /><br />
              Esta Norma Oficial Mexicana es aplicable a todos los juguetes elaborados
              con cualquier material, inclusive operados con pila, baterías y/o
              cualquier otra fuente de alimentación, que se comercialicen en el
              territorio de los Estados Unidos Mexicanos.
            </p>
          </div>
          <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-015.svg" alt="NOM 015 Logo" />
        </div>
        <div className="norm-info__container__image-container">
          <div>
            <p className="norm-info__container__text">
              <blockquote
                cite="https://www.dof.gob.mx/nota_detalle.php?codigo=5640655&fecha=14/01/2022#gsc.tab=0"
              >
                <h2 className="norm-info__container__sub-title">Esta Norma Oficial Mexicana
                  no es aplicable a</h2>
                Las réplicas de armas de fuego, en cuyo caso aplica la
                NOM-161-SCFI-2003.
              </blockquote>
            </p>
          </div>
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/no-aplica.svg"
            alt="NOM Logo"
          />
        </div>
        <blockquote cite="https://dof.gob.mx/nota_detalle.php?codigo=5033112&fecha=17/04/2008#gsc.tab=0">
          <div className="norm-info__container__image-container">

            <img
              loading="lazy"
              className="norm-info__container__image-container__image"
              src="/img/015-image-2.svg"
              alt="NOM Logo"
            />
            <div>
              <p className="norm-info__container__text">
                <h2 className="norm-info__container__sub-title">Términos y definiciones</h2>
                <dl>

                  <dt>Accesorio</dt>
                  <dd>Es aquel artículo que se utiliza como complemento de los
                    juguetes.</dd>

                  <dt>Advertencia</dt>
                  <dd>Leyenda recomendada por el fabricante que señala una situación
                    de alerta en el uso del juguete, que puede presentarse en
                    redacción libre en el juguete, en el instructivo o en su empaque
                    y que sea legible.</dd>

                  <dt>Comerciante</dt>
                  <dd>La persona física o moral que adquiere juguetes nacionales o
                    importados para su distribución y/o venta dentro del territorio
                    de los Estados Unidos Mexicanos.</dd>

                  <dt>Consumidor</dt>
                  <dd>Persona física o moral que adquiere o disfruta, como
                    destinatario final, juguetes.</dd>

                  <dt>Disfraz</dt>
                  <dd>Es aquella indumentaria o accesorio que, con fines de
                    entretenimiento, se utiliza para modificar temporalmente la
                    apariencia del consumidor.</dd>

                  <dt>Envase y/o empaque</dt>
                  <dd>Cualquier recipiente o envoltura en el cual está contenido el
                    producto para su venta al consumidor.</dd>

                  <dt>Etiqueta</dt>
                  <dd>Cualquier rótulo, marbete, inscripción, imagen u otra materia
                    descriptiva o gráfica, escrita, impresa, estarcida, marcada,
                    grabada en alto o bajo relieve, adherida o sobrepuesta al
                    juguete, a su envase o, cuando no sea posible por las
                    características del juguete, a su empaque.</dd>

                  <dt>Fabricante</dt>
                  <dd>Es la persona física o moral responsable de la transformación
                    de insumos en juguetes.</dd>


                  <dt>Fulminante</dt>
                  <dd>Cápsula de carácter explosivo, inflamable al choque o fricción.</dd>

                  <dt>Garantía</dt>
                  <dd>Documento mediante el cual, cuando se ofrezca, el fabricante
                    nacional, importador y/o comercializador responsable del juguete, se
                    compromete a responder del funcionamiento del mismo por un tiempo
                    determinado, en caso de que éste presente cualquier defecto de
                    fabricación o de los materiales utilizados.</dd>

                  <dt>Instructivo</dt>
                  <dd>Información escrita o gráfica recomendada sólo por el fabricante,
                    que explique el correcto funcionamiento, uso, ensamblado o armado
                    del juguete y que puede contener advertencias para el manejo seguro
                    y confiable del producto.</dd>

                  <dt>Insumo</dt>
                  <dd>Son las materias primas, partes y componentes susceptibles de ser
                    transformados en juguetes.</dd>

                  <dt>Insumo</dt>
                  <dd>Son las materias primas, partes y componentes susceptibles de ser
                    transformados en juguetes.</dd>

                </dl>
              </p>
            </div>
          </div>
        </blockquote>
        <NormInfo
          DOFDate='17/04/2008'
          VigorDate='17/01/2009'
          responsible='SECRETARIA DE ECONOMIA'
        />

        <blockquote cite="https://dof.gob.mx/nota_detalle.php?codigo=5033112&fecha=17/04/2008#gsc.tab=0">
          <div className="norm-info__container__image-container">
            <div>
              <p className="norm-info__container__text">
                <h3
                  id="clasificacion"
                  className="norm-info__container__sub-title"
                >Clasificación de juguetes según la NOM-015-SCFI-2007</h3>
                <br />
                <b>Juguete</b> <br />
                Cualquier producto o material concebido, destinado y fabricado de modo
                evidente a ser utilizado con finalidades de juego o entretenimiento, el
                cual puede usarse o disfrutarse en forma activa o pasiva.
                <br />
                <b>Juguete acuático</b><br />
                Es aquel que ha sido concebido, diseñado y construido para ser utilizado
                en el agua.
              </p>
            </div>
            <img
              loading="lazy"
              className="norm-info__container__image-container__image"
              src="/img/015-image-1.svg"
              alt="NOM Logo"
            />
          </div>
        </blockquote>
        <div className='norm-info__questions'>
          <h2 className="norm-info__container__sub-title">Preguntas frecuentes</h2>
          <ul className="accordion norm-info__container__not-transform-ul" id="accordion">
            <li className="accordion-item">
              <div id="heading1">
                <button
                  className="accordion-button norm-info__container__accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse1"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  ¿Cuál es la clasificación de juguetes según el etiquetado de la
                  NOM-015-SCFI-2007?
                </button>
              </div>
              <div
                id="collapse1"
                className="accordion-collapse collapse show"
                aria-labelledby="heading1"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  <a href="#clasificacion">Juguete acuático, Juguete científico o
                    equipo experimental, Juguete deportivo y recreativo, Juguete de
                    salón, Juguete educativo, Juguete funcional, Juguete montable.</a>
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse2"
                  aria-expanded="false"
                  aria-controls="collapse2"
                >
                  ¿Cuándo se aplica la NOM-015-SCFI-2007?
                </button>
              </div>
              <div
                id="collapse2"
                className="accordion-collapse collapse"
                aria-labelledby="heading2"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  Esta norma es aplicable para el etiquetado de juguetes que se
                  comercialicen en el territorio de los Estados Unidos Mexicanos.
                  <br /><br />Esta Norma Oficial Mexicana es aplicable a todos los
                  juguetes elaborados con cualquier material, inclusive operados con
                  pila, baterías y/o cualquier otra fuente de alimentación, que se
                  comercialicen en el territorio de los Estados Unidos Mexicanos.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading3">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse3"
                  aria-expanded="false"
                  aria-controls="collapse3"
                >
                  ¿Qué dice la NOM-015-SCFI-2007?
                </button>
              </div>
              <div
                id="collapse3"
                className="accordion-collapse collapse"
                aria-labelledby="heading3"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  Establece la información comercial que deben mostrar los juguetes
                  que se comercializan en México. Esta norma se aplica a todos los
                  juguetes, independientemente de los materiales con los que estén
                  hechos y si son operados por celdas, baterías u otras fuentes de
                  alimentación.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading4">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse4"
                  aria-expanded="false"
                  aria-controls="collapse4"
                >
                  ¿Qué dice la NOM-015-SCFI-2007?
                </button>
              </div>
              <div
                id="collapse4"
                className="accordion-collapse collapse"
                aria-labelledby="heading4"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  Establece la información comercial que deben mostrar los juguetes
                  que se comercializan en México. Esta norma se aplica a todos los
                  juguetes, independientemente de los materiales con los que estén
                  hechos y si son operados por celdas, baterías u otras fuentes de
                  alimentación.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading5">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse5"
                  aria-expanded="false"
                  aria-controls="collapse5"
                >
                  ¿Cuáles son los diferentes tipos de productos que se mencionan en la
                  NOM-015-SCFI-2007?
                </button>
              </div>
              <div
                id="collapse5"
                className="accordion-collapse collapse"
                aria-labelledby="heading5"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  Se mencionan juguetes en general, sin especificar tipos
                  particulares. Sin embargo, se excluyen las réplicas de armas de
                  fuego, que están reguladas por otra norma.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading6">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse6"
                  aria-expanded="false"
                  aria-controls="collapse6"
                >
                  ¿Cuándo entró en vigor la NOM-015-SCFI-2007?
                </button>
              </div>
              <div
                id="collapse6"
                className="accordion-collapse collapse"
                aria-labelledby="heading6"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  Fue
                  <a
                    href="https://dof.gob.mx/nota_detalle.php?codigo=5033112&fecha=17/04/2008#gsc.tab=0"
                  >publicada en el DOF el día 17/04/2008</a>
                  y de acuerdo al artículo transitorio primero entró en vigor 9 meses
                  posteriores a su publicación.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading7">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse7"
                  aria-expanded="false"
                  aria-controls="collapse7"
                >
                  ¿Qué cambios se le hicieron recientemente a la NOM-015-SCFI-2007?
                </button>
              </div>
              <div
                id="collapse7"
                className="accordion-collapse collapse"
                aria-labelledby="heading7"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  La NOM-015-SCFI-2007
                  <a
                    href="https://dof.gob.mx/nota_detalle.php?codigo=5033112&fecha=17/04/2008#gsc.tab=0:~:text=Esta%20Norma%20Oficial%20Mexicana%20cancela%20a%20la%20Norma%20Oficial%20Mexicana%20NOM%2D015%2DSCFI%2D1998%2C%20Informaci%C3%B3n%20comercial%2DEtiquetado%20en%20juguetes%2C%20publicada%20en%20el%20Diario%20Oficial%20de%20la%20Federaci%C3%B3n%20el%205%20de%20marzo%20de%201999."
                  >canceló a la NOM-015-SCFI-1998.</a>
                  <br />
                  <br />
                  Entre los principales cambios de la NOM se encuentra la definición
                  de Partes y accesorios pequeños, en los cuales se hace énfasis en la
                  colocación de leyendas precautorias, ya que éstos pudieran
                  representar un riesgo de asfixia para niños de corta edad. En el
                  caso de que los juguetes que operan con pila, baterías y/o cualquier
                  fuente de alimentación deberán indicarlo de manera clara en la
                  etiqueta, así como sus características eléctricas nominales.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading8">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse8"
                  aria-expanded="false"
                  aria-controls="collapse8"
                >
                  ¿Quién emite la NOM-015-SCFI-2007?
                </button>
              </div>
              <div
                id="collapse8"
                className="accordion-collapse collapse"
                aria-labelledby="heading8"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  Fue emitida por la Secretaría de Economía, y para ello trabajó el
                  Comité Consultivo Nacional de Normalización de Seguridad al Usuario,
                  Información Comercial y Prácticas de Comercio.
                  <br />
                  <br />
                  Cabe mencionar que para la emisión de la NOM-015-SCFI-2007 se
                  tuvieron que pasar por varias etapas. Primeramente, el 09 de febrero
                  del 2007 se publicó el Proyecto de Norma Oficial Mexicana
                  <a
                    href="https://dof.gob.mx/nota_detalle.php?codigo=5031556&fecha=13/03/2008#gsc.tab=0"
                  >PROY-NOM-015-SCFI-2004.</a>
                  Durante el plazo de 60 días naturales a partir de su publicación el
                  público en general pudo presentar comentarios sobre el contenido
                  citado del proyecto. Que con fecha 28 de noviembre de 2007, el
                  Comité Consultivo Nacional de Normalización de Seguridad al Usuario,
                  Información Comercial y Prácticas de Comercio, aprobó por unanimidad
                  la norma referida para ser publicada en el Diario Oficial de la
                  Federación el día 17 de abril del 2008.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading9">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse9"
                  aria-expanded="false"
                  aria-controls="collapse9"
                >
                  ¿Cómo saber si mi producto entra dentro de los requisitos de la
                  NOM-015-SCFI-2007?
                </button>
              </div>
              <div
                id="collapse9"
                className="accordion-collapse collapse"
                aria-labelledby="heading9"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  Si el producto que vas a comercializar o importar se encuentra
                  dentro de este campo de aplicación, entonces deberás de cumplir con
                  lo dispuesto en esta norma.
                  <br />
                  <br />
                  Entre los principales puntos que deberás de cumplir son:
                  <br />
                  <ul>
                    <li >■ Indicar el nombre genérico del
                      producto</li>
                    <li >■ Indicación de la cantidad del
                      producto</li>
                    <li >■ Responsable del fabricante o
                      importador</li>
                    <li >■ País de origen</li>
                    <li >■ Leyenda o símbolo que identifique
                      la edad del consumidor</li>
                    <li >■ En el caso de que el producto
                      opere con baterías y/o pilas, deberá de indicarlo</li>
                    <li >■ Y en general leyendas precautorias
                      específicas de acuerdo a la naturaleza del producto</li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading10">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse10"
                  aria-expanded="false"
                  aria-controls="collapse10"
                >
                  ¿Cómo puedo demostrar el cumplimiento de la NOM-015-SCFI-2007?
                </button>
              </div>
              <div
                id="collapse10"
                className="accordion-collapse collapse"
                aria-labelledby="heading10"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  <a href="https://www.cecsatrade.mx/#form">Contactando a CECSA</a>,
                  ya que nuestros grupo de expertos en la materia te dirán cuáles son
                  los requisitos que debe de llevar tu producto en la etiqueta, de esa
                  manera, evitaras demoras, pérdidas de tiempo, pagos de almacenaje,
                  re etiquetado
                  <br />
                  <br />
                  CECSA revisará la etiqueta, y procederá a hacerte observaciones para
                  que se realicen modificaciones, o en su caso, si la etiqueta se
                  encuentra correcta procederá a emitir la
                  <a href="https://www.cecsatrade.mx/constancy">Constancia de
                    Inspección.</a>
                  <br />
                  <br />
                  Lo que deberás hacer es presentar la Constancia de Inspección en la
                  aduana junto con el producto. Ambos deben de coincidir, de esa
                  manera podrás cumplir con las
                  <a
                    href="https://e.economia.gob.mx/guias/cumplir-con-las-regulaciones-no-arancelarias/"
                  >regulaciones y restricciones no arancelarias de etiquetado.</a>
                  Recuerda que la vigencia de la Constancia de Inspección es de manera
                  indefinida.
                  <br />
                  <br />
                  La única manera en que la constancia pierda su vigencia es que:<br
                  />
                  <ul>
                    <li >■ Se actualice la Norma
                      NOM-015-SCFI-2007</li>
                    <li >■ Que cambie la presentación del
                      producto</li>
                    <li >■ Que cambie la edad recomendada</li>
                    <li >■ Que se requieran especificar
                      leyendas precautorias</li>
                    <li >■ Que el producto cambie en su
                      presentación</li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading11">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse11"
                  aria-expanded="false"
                  aria-controls="collapse11"
                >
                  ¿Qué pasa si no se cumple con la NOM-015-SCFI-2007?
                </button>
              </div>
              <div
                id="collapse11"
                className="accordion-collapse collapse"
                aria-labelledby="heading11"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  De acuerdo a la Ley de la Infraestructura de la Calidad el no
                  cumplimiento a las disposiciones establecidas en esta Ley, pudiera
                  ser:<br />
                  <ul>
                    <li >■ Apercibimiento</li>
                    <li >■ Multas</li>
                    <li >■ Clausura temporal o definitiva,
                      que podrá ser parcial o total</li>
                    <li >■ Arresto administrativo hasta por
                      treinta y seis horas</li>
                    <li >■ Suspensión o prohibición de la
                      comercialización de bienes, productos y servicios, incluyendo la
                      inmovilización de los mismos para impedir su comercialización</li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading12">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse12"
                  aria-expanded="false"
                  aria-controls="collapse12"
                >
                  ¿Cómo importar un producto con la NOM-015-SCFI-2007?
                </button>
              </div>
              <div
                id="collapse12"
                className="accordion-collapse collapse"
                aria-labelledby="heading12"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  Para poder cumplir con el etiquetado de la NOM-015-SCFI-2007 podrás
                  optar por cualquiera de las siguientes alternativas:<br />
                  <ol>
                    <li>1) Presentar en la aduana la mercancía etiquetada y una copia
                      de una constancia de inspección emitida por CECSA.
                    </li>
                    <li>2) Etiquetar la mercancía en un almacén general de depósito,
                      bajo el régimen de depósito fiscal.
                    </li>
                    <li>3) Etiquetar la mercancía en un almacén particular. Esta
                      alternativa requiere cumplir con los siguientes requisitos: a)
                      Estar inscritos y activos en el padrón de importadores con una
                      antigüedad no menor a 2 años y b) haber importado al país
                      mercancías con un valor equivalente en moneda nacional a 100,000
                      dólares de los Estados Unidos de América, en una o varias
                      operaciones, durante los 12 meses anteriores a la fecha en que
                      se pretende hacer la importación.
                    </li>
                  </ol>
                  <br />
                  Para la alternativa 1) te sugerimos
                  <a href="https://www.cecsatrade.mx/#form">contactar a CECSA</a>
                  antes de realizar tu importación y que podamos resolver todas tus
                  dudas previo a que la mercancía se encuentre en aduana. Para esta
                  opción, será necesario que la mercancía ya se encuentre etiquetada
                  antes de la importación y que cuente con una
                  <a href="https://www.cecsatrade.mx/constancy">constancia de
                    inspección emitida por CECSA.</a>
                  <br />
                  <br />
                  La alternativa 2) es para empresas que desean etiquetar sus
                  productos en México, pero no cuentan con una trayectoria como
                  importadores. Usualmente es la manera en la que las nuevas empresas
                  dan cumplimiento a las regulaciones y restricciones no arancelarias
                  de normas oficiales mexicanas de información comercial.
                  <br />
                  <br />
                  La alternativa 3) es para aquellas empresas con una trayectoria de
                  al menos 2 años en el padrón de importadores y que cuentan con una o
                  varias operaciones que superan los 100,000 USD.
                  <br />
                  <br />
                  Cabe mencionar que éste no es el único requisito para importar
                  productos comprendidos en la NOM-015-SCFI-2007 por lo que te
                  recomendamos revisar el tema con tu agente aduanal, ya que este tipo
                  de productos requiere un padrón de importadores especial.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading13">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse13"
                  aria-expanded="false"
                  aria-controls="collapse13"
                >
                  ¿Dónde puedo encontrar el texto de la NOM-015-SCFI-2007?
                </button>
              </div>
              <div
                id="collapse13"
                className="accordion-collapse collapse"
                aria-labelledby="heading13"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  Lo podrá encontrar en el apartado de la
                  <a
                    href="https://dof.gob.mx/nota_detalle.php?codigo=5033112&fecha=17/04/2008"
                  >NOM-015-SCFI-2007 en el DIARIO OFICIAL DE LA FEDERACIÓN.</a>
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading14">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse14"
                  aria-expanded="false"
                  aria-controls="collapse14"
                >
                  ¿Qué hago si me detienen mi producto en la aduana y me piden cumplir
                  con la NOM-015-SCFI-2007?
                </button>
              </div>
              <div
                id="collapse14"
                className="accordion-collapse collapse"
                aria-labelledby="heading14"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  En primer lugar, deberás de solicitar la fracción arancelaria del
                  producto. Una vez que tengas la fracción arancelaria y corrobores
                  que le solicitan el cumplimiento de la NOM-015-SCFI-2007 deberás de
                  <a href="https://www.cecsatrade.mx/#form">contactar a CECSA</a>
                  para que te podamos asesorar al respecto.
                  <br />
                  <br />
                  Para cumplir con el etiquetado, podrás hacerlo en México o antes del
                  despacho aduanero (revisar las preguntas anteriores). Te
                  recomendamos analizar los costos de cada situación y evaluarlos.
                  Posteriormente cuando nos contactes, te brindaremos la asesoría y
                  apoyo para que puedas liberar tu producto de la aduana. El proceso
                  será rápido, cada minuto cuenta, por lo que te dejamos nuestro
                  correo (<a
                    href="mailto:contacto@cecsatrade.mx"
                    target="_blank"
                  >contacto@cecsatrade.mx</a>) y número de contacto por whatsapp (<a
                    href="https://wa.me/message/YZR3TYWZRARDH1"
                  >56 5019 0728</a>)
                </div>
              </div>
            </li>
          </ul>

        </div>
        <p className="norm-info__container__text">
          <h2 className="norm-info__container__sub-title">Referencias Normativas</h2>
          Esta Norma Oficial Mexicana se complementa con las siguientes normas
          oficiales mexicanas vigentes o las que en su momento las sustituyan:
          <br /><a
            href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002" target='_blank'
          >NOM-008-SCFI-2002</a>,Sistema General de Unidades de Medida,
          publicada en el Diario Oficial de la Federación el 27 de noviembre de
          2002.
          <br /><a
            target='_blank' href="https://dof.gob.mx/nota_detalle.php?codigo=688972&fecha=20/10/2003#:~:text=Esta%20Norma%20Oficial%20Mexicana%20establece,en%20el%20marcado%20del%20producto."
          >NOM-161-SCFI-2003</a>,Seguridad al usuario-Juguetes-Réplicas de armas
          de fuego-Especificaciones de seguridad y métodos de prueba, publicada
          en el Diario Oficial de la Federación el 20 de octubre de 2003.
        </p>

        <ServiceSteps
          withNewsLetter={false}
        />
      </section>
    </>
  )
}

export default Nom015