/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { JsonLd, NormInfo, ServiceSteps } from '../../components';
import { Helmet } from 'react-helmet';
import './styles.scss';

const Nom142: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-142-SCFI/SSA1-2014 - Bebidas alcoholicas';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org", "@type": "WebPage", "@id":
              "https://www.cecsatrade.mx/nom-142-scfi-ssa1-2014", "url":
              "https://www.cecsatrade.mx/nom-142-scfi-ssa1-2014", "name":
              "NOM-142-SCFI/SSA1-2014", "description": `La presente Norma tiene por objeto
  establecer las especificaciones sanitarias y disposiciones de etiquetado
  sanitario y comercial de las bebidas alcohólicas que se comercialicen en el
  territorio nacional.`, "isPartOf": {
              "@id":
                "https://www.cecsatrade.mx/#/schema/WebSite/1"
            }, "datePublished":
              "2023-01-31T08:08:40-06:00", "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": { "@id": "https://www.cecsatrade.mx/assets/142.svg" }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="Bebidas alcohólicas. Especificaciones
          sanitarias. Etiquetado sanitario y comercial"
        />
      </Helmet>
      <section className='norm-info'>
        <h1 className='norm-info__title'>NOM-142-SCFI/SSA1-2014</h1>
        <h2>Bebidas alcohólicas. Especificaciones
          sanitarias. Etiquetado sanitario y comercial.</h2>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text'>
            <h2>Objetivo y campo de aplicación</h2>
            <p>
              La presente Norma tiene por objeto establecer las especificaciones
              sanitarias y disposiciones de etiquetado sanitario y comercial de las
              bebidas alcohólicas que se comercialicen en el territorio nacional.
            </p>
          </div>
          <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-142.svg" alt="NOM 142 Logo" />
        </div>

        <div className="norm-info__container__image-container">
          <div>
            <p className="norm-info__container__text">
              <h2
                className="norm-info__container__sub-title"
                id="definiciones"
              >Definiciones</h2>
              <dl>

                <dt>Abocado o Abocamiento</dt>
                <dd>Procedimiento para suavizar el sabor de las bebidas alcohólicas
                  mediante la adición de aditivos permitidos en el Acuerdo por el
                  que se determinan los aditivos y coadyuvantes en alimentos,
                  bebidas y suplementos alimenticios, su uso y disposiciones
                  sanitarias.</dd>

                <dt>Alteración</dt>
                <dd>Producto o materia prima cuando, por acción de cualquier causa,
                  haya sufrido modificaciones en su composición intrínseca que lo
                  conviertan en nocivo para la salud o que modifiquen sus
                  características, siempre que éstas tengan repercusión en la
                  calidad sanitaria de los mismos.</dd>

                <dt>Alcohol etílico</dt>
                <dd>Producto obtenido por fermentación, principalmente alcohólica de
                  los mostos de las materias primas de origen vegetal que contienen
                  azúcares o de aquellas que contienen almidones sacarificables
                  (caña de azúcar, mieles incristalizables, jarabe de glucosa,
                  jarabes de fructosa, cereales, frutas, tubérculos, entre otras) y
                  que dichos mostos fermentados son sometidos a destilación y
                  rectificación. Su fórmula es CH3-CH2-OH. Según su contenido de
                  impurezas el alcohol etílico puede denominarse espíritu neutro,
                  alcohol etílico de calidad o alcohol etílico común cuando contiene
                  un máximo 7,5 mg; 12,5 mg o 60 mg de impurezas por 100 ml de
                  alcohol etílico anhidro, respectivamente.</dd>

              </dl>
            </p>
          </div>
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/142-image-1.svg"
            alt="NOM Logo"
          />
        </div>
        <p className="norm-info__container__text">
          <dl>

            <dt>Añejamiento</dt>
            <dd>Proceso de maduración al que se somete un producto alcohólico de por
              lo menos un año en recipientes de madera de roble blanco o encino,
              cuya capacidad no debe ser superior a 700 litros.</dd>

            <dt>Arbolito escarchado</dt>
            <dd>Al soporte vegetal que se puede introducir en las bebidas
              alcohólicas sobresaturadas de azucares, sobre el cual el exceso de
              éstos se haya cristalizado.</dd>

            <dt>Bebida alcohólica destilada</dt>
            <dd>al producto obtenido por destilación de líquidos fermentados que se
              hayan elaborado a partir de materias primas vegetales en las que la
              totalidad o una parte de sus azúcares fermentables, hayan sufrido como
              principal fermentación, la alcohólica, siempre y cuando el destilado
              no haya sido rectificado totalmente, por lo que el producto deberá
              contener las sustancias secundarias formadas durante la fermentación y
              que son características de cada bebida, con excepción del vodka,
              susceptibles de ser abocadas y en su caso añejadas o maduradas, pueden
              estar adicionadas de ingredientes y aditivos permitidos en el Acuerdo
              por el que se determinan los aditivos y coadyuvantes en alimentos,
              bebidas y suplementos alimenticios, su uso y disposiciones sanitarias.
              Con contenido alcohólico de 32,0 hasta 55,0% Alc. Vol.</dd>

            <dt>Bebida alcohólica fermentada</dt>
            <dd>al producto resultante de la fermentación principalmente alcohólica
              de materias primas de origen vegetal, pueden adicionarse de
              ingredientes y aditivos permitidos en el Acuerdo por el que se
              determinan los aditivos y coadyuvantes en alimentos, bebidas y
              suplementos alimenticios, su uso y disposiciones sanitarias. Con
              contenido alcohólico de 2,0 hasta 20,0% Alc. Vol.</dd>

          </dl>
        </p>
        <div className="norm-info__container__image-container">
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/142-image-2.svg"
            alt="NOM Logo"
          />
          <div>
            <p className="norm-info__container__text">
              <dl>

                <dt>Bebidas alcohólicas preparadas</dt>
                <dd>Productos elaborados a base de bebidas alcohólicas destiladas,
                  fermentadas, licores o mezclas de ellos, espíritu neutro, alcohol
                  de calidad o alcohol común o mezcla de ellos y agua, aromatizados
                  y saborizados con procedimientos específicos y que pueden
                  adicionarse de otros ingredientes, aditivos y coadyuvantes
                  permitidos en el Acuerdo por el que se determinan los aditivos y
                  coadyuvantes en alimentos, bebidas y suplementos alimenticios, su
                  uso y disposiciones sanitarias. Con un contenido alcohólico de 2,0
                  hasta 12,0% Alc. Vol.</dd>

                <dt>Coctel</dt>
                <dd>Producto elaborado a partir de bebidas alcohólicas destiladas,
                  fermentadas, licores, espíritu neutro, alcohol de calidad o
                  alcohol común o mezcla de ellos y agua, aromatizados y saborizados
                  con procedimientos específicos y que pueden adicionarse de
                  ingredientes así como de aditivos y coadyuvantes permitidos en el
                  Acuerdo por el que se determinan los aditivos y coadyuvantes en
                  alimentos, bebidas y suplementos alimenticios, su uso y
                  disposiciones sanitarias. Con un contenido alcohólico mayor de
                  12,0 y hasta 32,0% Alc. Vol.</dd>

                <dt>Falsificación</dt>
                <dd>Producto que se fabrica, envasa o vende, haciendo referencia a
                  una autorización que no existe; o se utiliza una autorización
                  otorgada legalmente a otro; o se imita al legalmente fabricado y
                  registrado. Fecha de consumo preferente: Fecha en que, bajo
                  determinadas condiciones de almacenamiento, expira el periodo
                  durante el cual el producto preenvasado es comercializable y
                  mantiene las cualidades específicas que se le atribuyen tácita o
                  explícitamente, pero después de la cual el producto preenvasado
                  puede ser consumido.</dd>

              </dl>
            </p>
          </div>
        </div>
        <p className="norm-info__container__text"><br />
          <dl>

            <dt>Ingrediente</dt>
            <dd>Cualquier sustancia o producto, incluidos los aditivos, que se
              emplee en la fabricación o preparación de la bebida y esté presente en
              el producto final, transformado o no. Leyenda precautoria: Cualquier
              texto o representación que prevenga al consumidor sobre la presencia
              de un ingrediente específico, o sobre los daños a la salud que pueda
              originar el abuso en el consumo de los productos regulados por esta
              Norma.</dd>

            <dt>Licor o crema</dt>
            <dd>Producto elaborado a base de bebidas alcohólicas destiladas,
              espíritu neutro, alcohol de calidad o alcohol común o mezcla de ellos;
              con un contenido no menor de 1,0% (m/v) de azúcares o azúcares
              reductores totales y agua; aromatizados y saborizados con
              procedimientos específicos y que pueden adicionarse de ingredientes,
              así como aditivos y coadyuvantes permitidos en el Acuerdo por el que
              se determinan los aditivos y coadyuvantes en alimentos, bebidas y
              suplementos alimenticios, su uso y disposiciones sanitarias. Con
              contenido alcohólico de 13,5 hasta 55,0% Alc. Vol.</dd>

            <dt>Límite máximo</dt>
            <dd>Cantidad establecida de aditivos, microorganismos, parásitos,
              materia extraña, plaguicidas, radionúclidos, biotoxinas, residuos de
              medicamentos, metales pesados y metaloides que no se deben exceder en
              la bebida o materia prima.</dd>

            <dt>Maduración</dt>
            <dd>Transformación lenta que le permite adquirir al producto alcohólico
              las características sensoriales deseadas, por procesos fisicoquímicos.</dd>

            <dt>Micro-oxigenación</dt>
            <dd>Tratamiento post-fermentativo, que consiste en el aporte controlado
              de pequeñas cantidades de oxígeno de forma continuada y lenta.</dd>

            <dt>Metal pesado y metaloide</dt>
            <dd>Elementos químicos que causan efectos indeseables en el metabolismo
              aun en concentraciones bajas. Su toxicidad depende de las dosis en que
              se ingieran, así como de su acumulación en el organismo.</dd>

            <dt>Métodos de ensayo (prueba)</dt>
            <dd>Procedimientos analíticos utilizados en el laboratorio para
              comprobar que un producto satisface las especificaciones que establece
              esta Norma.</dd>

            <dt>Prácticas de Higiene</dt>
            <dd>Medidas necesarias para garantizar la inocuidad de los productos.</dd>

            <dt>Preenvasado</dt>
            <dd>Proceso en virtud del cual, un producto es colocado en un envase de
              cualquier naturaleza, en ausencia del consumidor, y la cantidad de
              producto contenido en él no puede ser alterada, a menos que el envase
              sea abierto o modificado perceptiblemente.</dd>

            <dt>Símbolo(s)</dt>
            <dd>Elementos gráficos para proporcionar información de manera concisa.</dd>

          </dl>
        </p>
        <div className="norm-info__container__image-container">
          <div>
            <p className="norm-info__container__text">
              <dl>

                <dt>Rectificación</dt>
                <dd>Segunda separación realizada al producto. Este proceso se basa
                  en el hecho de que líquidos de diferentes composiciones químicas
                  no tienen el mismo punto de ebullición. Responsable de producto:
                  Persona física o moral que importe o elabore un producto o que
                  haya ordenado su elaboración total o parcial a un tercero.</dd>

                <dt>Superficie principal de exhibición</dt>
                <dd>Área donde se encuentra la denominación y la marca comercial del
                  producto.</dd>

                <dt>Tolerancia</dt>
                <dd>Diferencia entre el contenido de alcohol de la bebida declarado
                  en la etiqueta y el contenido de alcohol determinado
                  analíticamente.</dd>

                <dt>Viruta</dt>
                <dd>Trozos de madera de roble, utilizados para la elaboración de
                  vinos para transmitir al vino ciertos constituyentes provenientes
                  de la madera de roble.</dd>

                <dt>Disposiciones generales obligatorias de etiquetado</dt>
                <dd>Etiquetar por unidad, envase múltiple o colectivo. La etiqueta
                  tiene que estar fija hasta la compra tener símbolos claros,
                  visibles, legibles y en idioma español, tener información clara,
                  veraz y comprobable; no ser falsa, equívoca o que induzca al error
                  el consumidor</dd>

              </dl>
            </p>
          </div>
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/142-image-3.svg"
            alt="NOM Logo"
          />
        </div>
        <NormInfo
          DOFDate='23/03/2015'
          VigorDate='21/07/2015'
          responsible='SECRETARIA DE SALUD y SECRETARIA DE ECONOMIA'
        />
        <p className="norm-info__container__text">
          <h2
            className="norm-info__container__sub-title"
            id="referencias"
          >Referencias</h2>
          Esta Norma se complementa con las siguientes Normas Oficiales Mexicanas y
          Normas Mexicanas o las que las sustituyan:
          <ul>
            <li><a
              href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002"
            >NOM-008-SCFI-2002</a>, Sistema General de Unidades de Medida.</li>
            <li><a
              href="https://www.dof.gob.mx/nota_detalle.php?codigo=4936602&fecha=06/11/2006"
            >NOM-030-SCFI-2006</a>, Información comercial. Declaración de cantidad
              en la etiqueta - Especificaciones.
            </li>
            <li><a
              href="https://dof.gob.mx/nota_detalle.php?codigo=4879610&fecha=16/08/1995#gsc.tab=0"
            >NOM-117-SSA1-1994</a>, Bienes y Servicios. Método de prueba para la
              determinación de cadmio, arsénico, plomo, estaño, cobre, fierro, zinc
              y mercurio en alimentos, agua potable y agua purificada por
              espectrometría de absorción atómica.
            </li>
            <li>Modificación a la NOM-127-SSA1-1994, Salud ambiental. Agua para uso
              y consumo humano. Límites permisibles de calidad y tratamientos a que
              debe someterse el agua para su potabilización.
            </li>
            <li><a
              href="https://www.dof.gob.mx/normasOficiales/3980/salud/salud.htm"
            >NOM-251-SSA1-2009</a>, Prácticas de higiene para el proceso de
              alimentos, bebidas o suplementos alimenticios.
            </li>
            <li><a
              href="https://www.sinec.gob.mx/SINEC/Vista/Normalizacion/DetalleNMX.xhtml?pidn=d1NsbTR6WWI0MjdPOVVYQU8vY05sUT09"
            >NMX-V-004-NORMEX-2013</a>, Bebidas alcohólicas - Determinación de
              Furfural-Métodos de Ensayo (Prueba).
            </li>
            <li><a
              href="https://www.sinec.gob.mx/SINEC/Vista/Normalizacion/DetalleNMX.xhtml?pidn=aWlLeC9yUEFpelQvNTYwZFUrT0N1QT09"
            >NMX-V-005-NORMEX-2013</a>, Bebidas alcohólicas - Determinación de
              aldehídos, ésteres, metanol y alcoholes superiores-Métodos de ensayo
              (prueba).
            </li>
            <li><a
              href="https://www.sinec.gob.mx/SINEC/Vista/Normalizacion/DetalleNMX.xhtml?pidn=RVFZRHRLWFl3RU9QWEFDcDc4ZGxpUT09"
            >NMX-V-013-NORMEX-2013</a>, Bebidas alcohólicas - Determinación del
              contenido alcohólico (por ciento de alcohol en volumen a 20 °C (% Alc.
              Vol.) - Métodos de ensayo (prueba).
            </li>
            <li><a
              href="https://www.sinec.gob.mx/SINEC/Vista/Normalizacion/DetalleNMX.xhtml?pidn=SDd3cTRFRWZvMWZBL3pCS0EwOCtQZz09"
            >NMX-V-027-NORMEX-2009</a>, Bebidas alcohólicas - Determinación de
              anhídrido sulfuroso, dióxido de azufre (SO2) libre y total - Métodos
              de ensayo (Prueba).
            </li>
          </ul>
        </p>
        <ServiceSteps
          withNewsLetter={false}
        />
      </section>
    </>
  )
}

export default Nom142