/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { JsonLd, NormInfo, ServiceSteps } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom055: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-055-SCFI-1994';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "https://www.cecsatrade.mx/nom-055-scfi-1994",
            "url": "https://www.cecsatrade.mx/nom-055-scfi-1994",
            "name": "NOM-055-SCFI-1994",
            "description": "La presente Norma Oficial Mexicana tiene por objeto establecer los requisitos de información sanitaria y generales que para la venta y suministro de las pinturas, tintas, barnices, lacas y esmaltes, debe satisfacer el etiquetado de sus envases.",
            "isPartOf": {
              "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1"
            },
            "datePublished": "2023-08-17T08:08:40-06:00",
            "dateModified": "2023-08-17T08:43:03-06:00",
            "primaryImageOfPage": {
              "@id": "https://www.cecsatrade.mx/assets/055.svg"
            }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="NOM-055-SCFI-1994 · Información comercial - Materiales retardantes y/o inhibidores de flama y/o ignífugos - Etiquetado. · Objetivo y campo de aplicación."
        />
      </Helmet>
      <section className='norm-info'>
        <h1 className='norm-info__title'>NOM-055-SCFI-1994</h1>
        <h2>Información comercial - Materiales retardantes y/o inhibidores de flama y/o ignífugos - Etiquetado.</h2>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text'>
            <h2>Objetivo y campo de aplicación</h2>
            <p>
              La presente Norma Oficial Mexicana tiene por objeto establecer la información comercial de los materiales retardantes y/o inhibidores de flama y/o ignífugos, que deben ostentar los envases, empaques y/o embalajes para su comercialización en el territorio nacional.
            </p>
          </div>
          <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-055.svg" alt="NOM 055 Logo" />
        </div>
        <div className="norm-info__container__image-container">
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/055-image-1.svg"
            alt="NOM Logo"
          />
          <div>
            <p className="norm-info__container__text">
              <h2
                className="norm-info__container__sub-title"
                id="definiciones"
              >Definiciones</h2>
              <dl>
                <dt>Material retardante y/o inhibidor de flama</dt>
                <dd>Para efectos de esta Norma se entiende el producto químico, que aplicado a otros materiales los protege del fuego o evita que éste se propague.
                  <br />
                  <br />
                  Para las definiciones de los conceptos de envases, y embalajes, se toman las descritas en la Norma Oficial Mexicana NOM-030-SCFI.</dd>
              </dl>
            </p>
          </div>
        </div>
        <div className="norm-info__container__image-container">
          <p className="norm-info__container__text">
            <h2
              className="norm-info__container__sub-title"
              id="Marcado y etiquetado"
            >Marcado y etiquetado</h2>
            Los productos nacionales y/o importados deben ostentar en forma clara, legible e indeleble, la información siguiente:
            <br />
            <ul>
              <li>■ Nombre o razón social del fabricante.</li>
              <li>■ Marca comercial del producto.</li>
              <li>■ Nombre genérico del material y/o específico del mismo.</li>
              <li>■ Nombre técnico o químico del producto.</li>
              <li>■ Modo de empleo del producto (el fabricante debe especificar la forma de preparar y aplicar el producto para su uso correcto. Las indicaciones deben señalarse en instructivo anexo o en la etiqueta) - Nombre técnico o químico de los solventes a emplearse.</li>
              <li>■ Advertencias (el fabricante debe anotar las advertencias mínimas para preservar la seguridad y salud de las personas y la integridad del medio ambiente en general).</li>
              <li>■ La leyenda "Hecho en México" o la designación del país de origen.</li>
              <li>■ Fecha de caducidad.</li>
              <li>■ El Registro Federal de Contribuyente del fabricante.</li>
              <li>■ El Registro Federal de Contribuyente del importador. Este dato puede agregarse al momento de la comercialización. La información anterior debe ir en lugar visible en los envases y/o empaques y/o embalajes.</li>
            </ul>
          </p>
        </div>
        <NormInfo
          DOFDate='---'
          VigorDate='---'
          responsible='SECRETARÍA DE ECONOMIA'
        />
        <p className="norm-info__container__text">
          <h2
            className="norm-info__container__sub-title"
            id="referencias"
          >Referencias</h2>
          <ul>
            <li><a href="https://www.dof.gob.mx/nota_detalle.php?codigo=4936602&fecha=06/11/2006">NOM-030-SCFI </a>, Información comercial - Declaración de cantidad en etiqueta especificaciones. </li>
            <li><a href="https://www.gob.mx/cms/uploads/attachment/file/745343/nmx-z-12-2-1987_compressed__2_.pdf">NMX-Z-12 </a>, Muestreo para la inspección por atributos.</li>
          </ul>
        </p>
        <ServiceSteps
          withNewsLetter={false}
        />
      </section>
    </>
  )
}

export default Nom055