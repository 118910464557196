/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { JsonLd, NormInfo, ServiceSteps } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom055: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-139-SCFI-2012';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "https://www.cecsatrade.mx/nom-139-scfi-2012",
            "url": "https://www.cecsatrade.mx/nom-139-scfi-2012",
            "name": "NOM-139-SCFI-2012",
            "description": "La presente Norma Oficial Mexicana tiene por objeto establecer los requisitos de información sanitaria y generales que para la venta y suministro de las pinturas, tintas, barnices, lacas y esmaltes, debe satisfacer el etiquetado de sus envases.",
            "isPartOf": {
              "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1"
            },
            "datePublished": "2023-08-17T08:08:40-06:00",
            "dateModified": "2023-08-17T08:43:03-06:00",
            "primaryImageOfPage": {
              "@id": "https://www.cecsatrade.mx/assets/139.svg"
            }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="Es aplicable al etiquetado del extracto natural de vainilla, extracto concentrado de vainilla, saborizante artificial de vainilla, saborizante natural de vainilla y vainilla en polvo que se comercializan dentro del territorio de los Estados Unidos Mexicanos."
        />
      </Helmet>
      <section className='norm-info'>
        <h1 className='norm-info__title'>NOM-139-SCFI-2012</h1>
        <h2>Información comercial-Etiquetado de extracto natural de vainilla (Vanilla spp), derivados y sustitutos.</h2>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text'>
            <h2>Objetivo y campo de aplicación</h2>
            <p>
              Es aplicable al etiquetado del extracto natural de vainilla, extracto concentrado de vainilla, saborizante artificial de vainilla, saborizante natural de vainilla y vainilla en polvo que se comercializan dentro del territorio de los Estados Unidos Mexicanos.
            </p>
          </div>
          <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-139.svg" alt="NOM 139 Logo" />
        </div>
        <div className="norm-info__container__image-container">
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/139-image-1.svg"
            alt="NOM Logo"
          />
          <div>
            <p className="norm-info__container__text">
              <h2
                className="norm-info__container__sub-title"
                id="definiciones"
              >Definiciones</h2>
              <dl>
                <dt>Extracto</dt>
                <dd>Al producto obtenido de los vegetales por maceración, percolación, destilación u otros procedimientos que permitan extraerles los principales saboreadores y aromatizantes.</dd>

                <dt>Extracto natural de vainilla</dt>
                <dd>Solución hidroalcohólica de vaina de vainilla beneficiada, color ámbar obtenida mediante diferentes procesos, tales como la maceración, percolación o filtración, entre otros. </dd>

                <dt>Extracto concentrado de vainilla</dt>
                <dd>Aquel extracto de vainilla que de acuerdo al UCV presenta una concentración doble (2x), triple (3x) o mayor. </dd>

                <dt>Oleorresinas de vainilla</dt>
                <dd>Es el producto que se obtiene de la vainilla beneficiada mediante una extracción con solventes eliminados total o parcialmente, perdiendo durante el proceso, constituyentes aromáticos. </dd>

                <dt>Saboreador, saborizante o aromatizante natural</dt>
                <dd>A la preparación de sustancias o sus mezclas obtenidas exclusivamente por procesos físicos, a partir de vegetales o de materias primas de origen animal en su estado natural o procesadas o por fermentación de materias lácteas y que son aptas para consumo humano. </dd>

              </dl>
            </p>
          </div>
        </div>
        <div className="norm-info__container__image-container">
          <div>
            <p className="norm-info__container__text">
              <dl>
                <dt>Saboreador, saborizante o aromatizante sintético-artificial</dt>
                <dd>A las sustancias que no han sido aún identificadas en productos naturales procesados o no y que son aptas para consumo humano. </dd>

                <dt>Vainilla beneficiada o curada</dt>
                <dd>Fruto sometido a un proceso de beneficiado y que ha desarrollado el aroma, color y sabor característicos de la vainilla, principalmente mediante la acción metabólica de sus enzimas. </dd>

                <dt>Vainilla en polvo o Polvo vainillado</dt>
                <dd>Es una mezcla de frutos beneficiados de vainilla molida u oleorresina de vainilla o ambas, mezcladas con uno o más de los siguientes ingredientes: azúcar, dextrosa, lactosa, almidón comestible, sólidos de jarabe de maíz o goma de acacia.
                  Vainillina Principal componente aromático de la vainilla, cuyo nombre químico corresponde a 3-metoxi-4- hidroxibenzaldehído.</dd>

                <dt>Unidad de Concentración de Vainilla (UCV)</dt>
                <dd>Extracto obtenido a partir de 100 g de vaina de vainilla beneficiada, que presente un 25% de humedad, en un litro de solvente, equivalente a por lo menos 0,11 g de vainillina en 100 ml. Una UCV= X donde: 1 UCV corresponde a un valor = 0,11 g de vainillina en 100 g de vaina de vainilla beneficiada, con la humedad señalada, y ese valor se considera como X 4. Símbolos y abreviaturas % por ciento g gramo mL, ml mililitro v/v volumen/volumen UCV unidad de concentración de vainilla</dd>

              </dl>
            </p>
          </div>
        </div>
        <NormInfo
          DOFDate='---'
          VigorDate='---'
          responsible='Secretaría de Economía'
        />
        <p className="norm-info__container__text">
          <h2
            className="norm-info__container__sub-title"
            id="referencias"
          >Referencias</h2>
          Esta Norma Oficial Mexicana se complementa con las siguientes Normas Oficiales Mexicanas y Normas Mexicanas vigentes: <br />
          <ul>
            <li><a
              href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002"
            >NOM-008-SCFI-2002</a>NOM-008-SCFI-2002, Sistema General de Unidades de Medida, publicada en el Diario Oficial de la Federación el 27 de noviembre de 2002.</li>
            <li><a
              href="https://www.dof.gob.mx/nota_detalle.php?codigo=4936602&fecha=06/11/2006#gsc.tab=0"
            >NOM-030-SCFI- 2006</a>, Información comercial - Declaración de cantidad en la etiqueta - Especificaciones, publicada en el Diario Oficial de la Federación el 6 de noviembre de 2006. </li>
            <li><a
              href="https://www.dof.gob.mx/normasOficiales/8150/seeco11_C/seeco11_C.html"
            >NOM-051-SCFI/SSA1-2010</a>, Especificaciones generales de etiquetado para alimentos y bebidas no alcohólicas preenvasados-Información comercial y sanitaria, publicada en el Diario Oficial de la Federación el 5 de abril de 2010. Todos aquellos que utilizan los niños en la escuela y que son susceptibles de chuparse, lamerse, masticarse, tomando en cuenta el comportamiento normal y predecible de los niños.</li>
          </ul>
        </p>
        <ServiceSteps
          withNewsLetter={false}
        />
      </section>
    </>
  )
}

export default Nom055