/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { JsonLd, NormInfo, ServiceSteps } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom024: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-024-SCFI-2013 - Electrónicos';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org", "@type": "WebPage", "@id":
              "https://www.cecsatrade.mx/nom-024-scfi-2013", "url":
              "https://www.cecsatrade.mx/nom-024-scfi-2013", "name": "NOM-024-SCFI-2013",
            "description": `Esta Norma Oficial Mexicana tiene por objeto establecer los
  requisitos de información comercial que deben ostentar los empaques,
  instructivos y garantías para los productos electrónicos, eléctricos y
  electrodomésticos, así como sus accesorios y consumibles, destinados al
  consumidor final, cuando éstos se comercialicen en territorio de los Estados
  Unidos Mexicanos.`, "isPartOf": {
              "@id":
                "https://www.cecsatrade.mx/#/schema/WebSite/1"
            }, "datePublished":
              "2023-01-31T08:08:40-06:00", "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": { "@id": "https://www.cecsatrade.mx/assets/024.svg" }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="Información comercial para empaques,
          instructivos y garantías de los productos electrónicos, eléctricos y
          electrodomésticos."
        />
      </Helmet>
      <section className='norm-info'>
        <h1 className='norm-info__title'>NOM-024-SCFI-2013</h1>
        <h2>Información comercial para empaques,
          instructivos y garantías de los productos electrónicos, eléctricos y
          electrodomésticos.</h2>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text'>
            <h2>Objetivo</h2>
            <p>
              Esta Norma Oficial Mexicana tiene por objeto establecer los requisitos de
              información comercial que deben ostentar los empaques, instructivos y
              garantías para los productos electrónicos, eléctricos y electrodomésticos,
              así como sus accesorios y consumibles, destinados al consumidor final,
              cuando éstos se comercialicen en territorio de los Estados Unidos
              Mexicanos
            </p>
          </div>
          <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-024.svg" alt="NOM 015 Logo" />
        </div>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text'>
            <h2>Campo de aplicación</h2>
            <p>
              Esta Norma Oficial Mexicana es aplicable a los productos nuevos,
              reconstruidos, usados o de segunda mano, así como los repuestos,
              accesorios y consumibles que se comercialicen en el territorio de los
              Estados Unidos Mexicanos.
              <br />
              <br />
              Los repuestos, accesorios y consumibles, internos y externos, de productos
              electrónicos, eléctricos y electrodomésticos que estén destinados para
              expenderse a granel o para efectos de reposición dentro de garantía, no
              requieren del instructivo, garantía, ni de la información comercial a que
              se refiere esta Norma Oficial Mexicana, aun cuando sí requieran de las
              advertencias cuando sean productos peligrosos.
              <br />
              <br />
              Para efectos de los empaques, instructivos y garantías de los productos
              electrónicos, eléctricos y electrodomésticos, considerados como
              reconstruidos, usados o de segunda mano, no les es aplicable la
              <a
                href="https://dof.gob.mx/nota_detalle.php?codigo=4757871&fecha=12/07/1993#gsc.tab=0"
              >Norma Oficial Mexicana NOM-017-SCFI-1993.</a>
              <br />
              <h3 className="norm-info__container__sub-title" id="no-aplica">No aplica a:</h3>
              Los productos y sus repuestos, accesorios, consumibles, partes componentes
              internos y externos de productos electrónicos y electrodomésticos para
              efectos de respaldo o reposición dentro de la garantía en el punto de
              entrada al país y no estarán sujetos a la evaluación de conformidad por
              parte de la unidad de inspección.
              <br />
              <h3
                className="norm-info__container__sub-title"
                id="referencias"
              >Referencias</h3>
              Esta Norma Oficial Mexicana se complementa con las siguientes Normas
              Oficiales Mexicanas vigentes o las que las sustituyan:
              <br /><a
                href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002"
              >NOM-008-SCFI-2002</a>, "Sistema General de Unidades de Medida", publicada
              en el Diario Oficial de la Federación el 27 de noviembre de 2002.
              <br /><a
                href="https://dof.gob.mx/nota_detalle.php?codigo=4757871&fecha=12/07/1993#gsc.tab=0"
              >NOM-017-SCFI-1993</a>, "Información comercialâEtiquetado de artículos
              reconstruidos, usados o de segunda mano, de segunda línea, discontinuados
              y fuera de especificaciones", publicada en el Diario Oficial de la
              Federación el 29 de octubre de 1993.
            </p>
          </div>
        </div>
        <blockquote cite="https://dof.gob.mx/nota_detalle.php?codigo=5033112&fecha=17/04/2008#gsc.tab=0">
          <div className="norm-info__container__image-container">

            <img
              loading="lazy"
              className="norm-info__container__image-container__image"
              src="/img/024-image-1.svg"
              alt="NOM Logo"
            />
            <div>
              <p className="norm-info__container__text">
                <h2 className="norm-info__container__sub-title">Definiciones</h2>
                <dl>

                  <dt>Accesorio</dt>
                  <dd>Es la parte de un producto electrónico, eléctrico o
                    electrodoméstico que se adquiere en forma separada y opcional y
                    que puede o no formar parte del producto original y que puede, o
                    no, requerir energía eléctrica para cumplir su función.</dd>

                  <dt>Advertencia</dt>
                  <dd>Información escrita o por medio de símbolos que invitan al
                    consumidor a poner atención sobre los riesgos durante el uso del
                    aparato.</dd>

                  <dt>Artículo reconstruido</dt>
                  <dd>Es aquel artículo que se ha vuelto a construir o es renovado o
                    reparado, sustituyéndole las piezas defectuosas o de mal
                    funcionamiento por piezas nuevas y que se expende al público en
                    general.</dd>

                </dl>
              </p>
            </div>
          </div>
        </blockquote>
        <blockquote cite="https://dof.gob.mx/nota_detalle.php?codigo=5033112&fecha=17/04/2008#gsc.tab=0">
          <div className="norm-info__container__image-container">
            <div>
              <p className="norm-info__container__text">
                <dl>

                  <dt>Artículo usado o de segunda mano</dt>
                  <dd>Es aquel artículo que ya ha sido usado y es puesto a la venta del
                    público en general sin reconstruir o renovar.</dd>

                  <dt>Consumible</dt>
                  <dd>Es aquel elemento que se utiliza en un producto electrónico,
                    eléctrico o electrodoméstico y que sufre un desgaste o se agota
                    parcial o totalmente por su propia función de uso.</dd>

                  <dt>Consumidor</dt>
                  <dd>Es la persona física o moral que adquiere, realiza o disfruta como
                    destinatario final bienes, productos o servicios. No se considera
                    consumidor quien adquiera, almacene, utilice o consuma bienes o
                    servicios con objeto de integrarlos a procesos de producción,
                    transformación, comercialización o prestación de servicios a
                    terceros.</dd>

                </dl>
              </p>
            </div>

            <img
              loading="lazy"
              className="norm-info__container__image-container__image"
              src="/img/024-image-2.svg"
              alt="NOM Logo"
            />
          </div>
        </blockquote>

        <blockquote cite="https://dof.gob.mx/nota_detalle.php?codigo=5033112&fecha=17/04/2008#gsc.tab=0">
          <div className="norm-info__container__image-container">
            <div>
              <p className="norm-info__container__text">
                <dl>

                  <dt>Empaque</dt>
                  <dd>Es el medio por el cual se evitan daños al producto o
                    productos, siempre y cuando éste sea empleado en forma apropiada
                    durante y a través de su transportación y manejo.</dd>

                  <dt>Etiqueta</dt>
                  <dd>Es cualquier rótulo, marbete, inscripción, imagen u otra
                    materia descriptiva o gráfica escrita, impresa, marcada, grabada
                    en alto o bajo relieve, adherida o sobrepuesta al producto, a su
                    envase o empaque, o cuando no sea posible por las
                    características del producto o su envase, al embalaje.</dd>

                  <dt>Garantía</dt>
                  <dd>Es el documento mediante el cual el fabricante y/o importador
                    se compromete a respaldar por un tiempo determinado el producto
                    o, en su caso, el accesorio, parte o componente contra cualquier
                    defecto de los materiales y/o mano de obra empleados en la
                    fabricación de los mismos.</dd>

                  <dt>Instructivo</dt>
                  <dd>Es el documento, incluido aquel presentado en medios de
                    almacenamiento de información electrónica, que contiene las
                    instrucciones de uso, manejo, precauciones que se deben tener y,
                    en su caso, las advertencias y datos para la instalación,
                    cuidado y mantenimiento del producto, dirigidas al consumidor.</dd>

                  <dt>Medio de almacenamiento de información electrónica</dt>
                  <dd>Es aquel en el cual puede ser almacenada cualquier tipo de
                    información del producto. Estos pueden ser discos duros, discos
                    flexibles, CD's, DVD, memorias electrónicas, direcciones de páginas
                    de Internet o similares.</dd>

                  <dt>Producto a granel</dt>
                  <dd>Producto que debe pesarse, contarse o medirse en presencia del
                    consumidor por no encontrarse preenvasado al momento de su venta.</dd>

                  <dt>Producto eléctrico</dt>
                  <dd>Equipo que se utiliza para propósitos de generación, conversión,
                    transmisión, distribución o utilización de energía eléctrica, tales
                    como máquinas, transformadores, aparatos, instrumentos de medición,
                    dispositivos de protección, equipo para alambrado de sistemas y
                    aparatos. Nota. Esto incluye subensambles, equipo (tales como
                    tabletas de circuitos impresos, conectores, gabinetes) e
                    instalaciones, tal como esté definido bajo contrato.</dd>

                  <dt>Producto electrodoméstico</dt>
                  <dd>Aparato eléctrico con o sin elementos calefactores, operados por
                    motor o accionados magnéticamente para uso doméstico o similar, que
                    utilizan para su alimentación la energía eléctrica de la red
                    pública, así como de otras fuentes de energía como pilas, baterías,
                    acumuladores o autogeneración.
                  </dd>

                  <dt>Producto electrónico</dt>
                  <dd>Equipo eléctrico cuya función principal se lleva a cabo por el uso
                    de componentes a través de la conducción de electrones o iones en
                    movimiento en semiconductores, en un vacío o en un gas.</dd>

                  <dt>No aplica</dt>
                  <dd>A los productos y sus repuestos, accesorios, consumibles, partes
                    componentes internos y externos de productos eléctronicos y
                    electrodomésticos para efectos de respaldo o reposición dentro de la
                    garantía en el punto de entrada al país y no estrán sujetos a la
                    evaluación de conformidfad por parte de la unidad de inspección.</dd>

                </dl>
              </p>
            </div>
          </div>
        </blockquote>
        <NormInfo
          DOFDate='12/08/2013'
          VigorDate='11/10/2013'
          responsible='SECRETARIA DE ECONOMIA '
        />
        <ServiceSteps
          withNewsLetter={false}
        />
      </section>
    </>
  )
}

export default Nom024