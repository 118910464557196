/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { JsonLd, NormInfo, ServiceSteps } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom003: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-004-SE-2021 - Textiles';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "https://www.cecsatrade.mx/nom-004-se-2021",
            "url": "https://www.cecsatrade.mx/nom-004-se-2021",
            "name": `NOM-004-SE-2021
            Etiquetado de productos textiles", "description": "La presente Norma Oficial
            Mexicana, es aplicable a los productos textiles, prendas de vestir, sus
            accesorios y ropa de casa antes de su internación al país, elaborados con
            materiales textiles aun cuando contengan plásticos u otros materiales y que se
            destinen al consumidor final y que se comercialicen dentro del territorio de
            los Estados Unidos Mexicanos.`,
            "isPartOf": {
              "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1"
            },
            "datePublished": "2023-01-31T08:08:40-06:00",
            "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": {
              "@id": "https://www.cecsatrade.mx/assets/004.svg"
            }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="La presente Norma Oficial Mexicana establece la información comercial en
              los productos textiles, prendas de vestir, sus accesorios y ropa de casa.
              La información comercial a que se refiere la presente Norma Oficial
              Mexicana, es aplicable a los productos textiles, prendas de vestir, sus
              accesorios y ropa de casa antes de su internación al país, elaborados con
              materiales textiles aun cuando contengan plásticos u otros materiales y
              que se destinen al consumidor final y que se comercialicen dentro del
              territorio de los Estados Unidos Mexicanos."
        />
      </Helmet>
      <section className='norm-info'>
        <h1 className='norm-info__title'>NOM-004-SE-2021</h1>
        <h2>Información comercial-Etiquetado de productos
          textiles, prendas de vestir, sus accesorios y ropa de casa.</h2>
        <div className='norm-info__image-container'>
          <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-004.svg" alt="NOM 004 Logo" />
          <div className='norm-info__image-container__text'>
            <h2>Objetivo y campo de aplicación</h2>
            <p>
              La presente Norma Oficial Mexicana establece la información comercial en
              los productos textiles, prendas de vestir, sus accesorios y ropa de casa.
              La información comercial a que se refiere la presente Norma Oficial
              Mexicana, es aplicable a los productos textiles, prendas de vestir, sus
              accesorios y ropa de casa antes de su internación al país, elaborados con
              materiales textiles aun cuando contengan plásticos u otros materiales y
              que se destinen al consumidor final y que se comercialicen dentro del
              territorio de los Estados Unidos Mexicanos.
              <br /><br />
              La presente Norma Oficial Mexicana es aplicable al responsable del
              producto objeto de esta norma.
              <br /><br />
              La presente Norma Oficial Mexicana es aplicable a los productos textiles,
              prendas de vestir, sus accesorios y ropa de casa, cuya composición textil
              sea igual o superior al 50% con relación a la masa.
            </p>
          </div>
        </div>
        <h3 className="norm-info__container__sub-title" id="puntos-a-evaluar">Puntos a
          evaluar</h3>
        <p className="norm-info__container__text">
          El etiquetado de los productos textiles, prendas de vestir, sus accesorios
          y ropa de casa comprende cinco rubros importantes:
          <br /><br />
        </p>
        <ul>
          <li>I. La información del responsable del producto.</li>
          <li>II. País de origen.</li>
          <li>III. La composición de fibras (descripción de insumos).</li>
          <li>IV. Las instrucciones de cuidado (conservación y limpieza).</li>
          <li>V. Las tallas de las prendas y dimensiones o medidas en la ropa de
            casa y textiles.</li>
        </ul>
        <p className="norm-info__container__text">
          La presente Norma Oficial Mexicana es aplicable al responsable del
          producto objeto de esta norma.
        </p>
        <div className="norm-info__container__image-container">
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/004-image-1.svg"
            alt="NOM Logo"
          />
          <div>
            <p className="norm-info__container__text">
              <h3
                className="norm-info__container__sub-title"
                id="textiles"
              >Textiles</h3>
              Los textiles y demás productos no incluidos deben contar con la
              siguiente información en forma legible: La información comercial que
              requiere esta Norma se podrá expresar además en otro idioma, siempre y
              cuando se exprese en idioma español.
              <br />
              <br />
              <h3
                className="norm-info__container__sub-title"
                id="cuando-el-producto-tenga-forro"
              >Cuando el producto tenga forro</h3>
              Esta información puede presentarse en la misma etiqueta o en otra,
              siempre que se indique que es la información correspondiente al forro,
              mediante la indicación forro o alguna otra equivalente.
              <br />
              <br />
              <h3 className="norm-info__container__sub-title" id="marca-comercial">Marca
                comercial</h3>
              Debe señalarse la marca comercial del producto.
              <br />
              <br />
              <h3
                className="norm-info__container__sub-title"
                id="descripcion-de-insumos"
              >Descripción de insumos.</h3>
              El fabricante nacional o el importador debe expresar el insumo en
              porcentaje,con relación a la masa, de las diferentes fibras que
              integran el producto en orden del predominio de dicho porcentaje.
              <br />
              <br />
            </p>
          </div>
        </div>
        <div className="norm-info__container__image-container">
          <div>
            <p className="norm-info__container__text">
              <blockquote
                cite="https://www.dof.gob.mx/nota_detalle.php?codigo=5640655&fecha=14/01/2022#gsc.tab=0"
              >
                <b>Esta Norma Oficial Mexicana no aplica a </b>
                cobertores eléctricos, pañales desechables, toallas sanitarias,
                hisopos, toallitas húmedas, cubrebocas quirúrgicos, prendas de vestir
                desechables, juguetes confeccionados con materiales textiles,
                disfraces, muebles, extensibles de reloj de material textil, cordones
                textiles, correas, correas porta gafete de material textil, porta
                gafetes, escudos, parches, banderas, cierres y/o cremalleras, botones
                y hebillas forrados de material textil, paños, guantes para retirar
                fuentes del horno, estuches para maquillajes, fibras de limpieza
                desechables y los destinados a utilizarse como envase o embalaje,
                además de insumos industriales que se destinen a la fabricación de
                bienes así como aquellos que no se destinen al consumidor final, tales
                como fibras y/o filamentos, hilos, telas tejidas y no tejidas,
                pasamanería, trapos mutilados o picados, recortes de tela que se
                empleen para la fabricación industrial de los productos objeto de esta
                norma y a textiles técnicos que se utilicen en otras industrias, tales
                como filtros, cintas adhesivas con base textil, sujetadores mecánicos,
                borlas, estopa, discos abrasivos o bien se encuentre regulada su
                información comercial por otra Norma Oficial Mexicana específica.
                características del producto.
              </blockquote>
            </p>
          </div>
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/no-aplica.svg"
            alt="NOM Logo"
          />
        </div>
        <p className="norm-info__container__text">
          <h2 className="norm-info__container__sub-title">Referencias Normativas</h2>
          Los siguientes documentos normativos vigentes o los que los sustituyan,
          son indispensables para la aplicación de esta Norma Oficial Mexicana:
          <br />
          <a
            href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002#:~:text=Esta%20Norma%20Oficial%20Mexicana%20tiene,todos%20los%20sectores%20del%20pa%C3%ADs."
            target="_blank"
          ><b>NOM-008-SCFI-2002</b></a>, Sistema General de Unidades de Medida,
          publicada en el Diario Oficial de la Federación el 27 de noviembre de 2002
          <br />
          <a
            href="https://www.sinec.gob.mx/SINEC/Vista/Normalizacion/DetalleNMX.xhtml?pidn=UDFsNUc4a1V6QVNJMWtOYlBhUExCdz09"
            target="_blank"
          ><b>NMX-A-2076-INNTEX-2013</b></a>, Industria textil-Fibras
          químicas-Nombres genéricos (Cancela a la NMX-A-099-INNTEX- 2007),
          publicada su declaratoria de vigencia en el Diario Oficial de la
          Federación el 06 de noviembre de 2014.
          <br />
          <a
            href="https://www.sinec.gob.mx/SINEC/Vista/Normalizacion/DetalleNMX.xhtml?pidn=N1JFUE1palVqeEhUN1RyV1k0MEdQdz09"
            target="_blank"
          ><b>NMX-A-6938-INNTEX-2013</b></a>, Industria textil-Fibras
          naturales-Nombres genéricos y definiciones (Cancela a la NMX-A-099-
          INNTEX-2007), publicada su declaratoria de vigencia en el Diario Oficial
          de la Federación el 06 de noviembre de 2014.
          <br /><a
            href="https://www.dof.gob.mx/nota_detalle.php?codigo=5398716&fecha=30/06/2015"
            target="_blank"
          ><b>NMX-A-3758-INNTEX-2014</b></a>, TextilesCódigo de generación de
          etiquetas de cuidado con el uso de símbolos (Cancela a la
          NMX-A-240-INNTEX-2009), publicada su declaratoria de vigencia en el Diario
          Oficial de la Federación el 16 de agosto de 2017.
          <br /><a
            href="https://dof.gob.mx/nota_detalle.php?codigo=5146201&fecha=04/05/2010"
            target="_blank"
          ><b>NMX-A-029-INNTEX-2010</b></a>, Industria textil-Tejidos de
          calada-Telas autoextinguibles-Especificaciones, publicada su declaratoria
          de vigencia en el Diario Oficial de la Federación el 04 de mayo de 2010.
          <b>ISO 13688:2013</b>
          Protective clothing General requirements.
        </p>

        <NormInfo
          DOFDate='14/01/2022'
          VigorDate='04/01/2023'
          responsible='SECRETARIA DE ECONOMIA'
        />
        <blockquote cite="https://www.dof.gob.mx/nota_detalle.php?codigo=5640655&fecha=14/01/2022#gsc.tab=0">
          <div className="norm-info__container__image-container">
            <div>
              <p className="norm-info__container__text">
                <h2 className="norm-info__container__sub-title">Términos y definiciones</h2>
                Para los propósitos de esta Norma Oficial Mexicana, se aplican los
                términos y definiciones siguientes:
                <br />
                <br />
                <dl>

                  <dt>Accesorio</dt>
                  <dd>Artículo que se utiliza como ornamento en las prendas de vestir o
                    como complemento de las mismas.</dd>

                  <dt>Alfombra</dt>
                  <dd>Revestimiento de material textil para cualquier superficie y que
                    requiera de un servicio de instalación.</dd>

                  <dt>Análogo</dt>
                  <dd>Adjetivo que denota aspectos o características iguales o similares
                    a otro concepto con el que se compara.</dd>

                  <dt>Armado</dt>
                  <dd>Elemento destinado para ser utilizado en el interior o exterior de
                    cualquier producto objeto de esta norma con la finalidad de mantener
                    la forma del producto durante su vida útil.</dd>

                  <dt>Comercializador</dt>
                  <dd>Persona física o moral responsable de la venta nacional de los
                    productos objeto de esta norma.</dd>

                  <dt>Consumidor o consumidor final</dt>
                  <dd>Persona física o moral que adquiere, realiza o disfruta como
                    destinatario final los bienes o productos objeto de la presente NOM.</dd>

                  <dt>Nota 1 a la entrada</dt>
                  <dd>Los productos y su información comercial objeto de la presente NOM
                    son realizados o disfrutados por los consumidores, además de
                    aquellos que se encuentran en el punto de venta para ser adquiridos
                    o consumidos.</dd>

                  <dt>Reasiento</dt>
                  <dd>Funda removible elaborada con material textil, cuya única función
                    es cubrir un objeto que es utilizado como asiento.</dd>

                  <dt>Etiqueta</dt>
                  <dd>Es cualquier marcaje de signo o dispositivo impreso, tejido,
                    estampado o bordado.</dd>

                  <dt>Etiqueta permanente</dt>
                  <dd>Es aquella incorporada al producto, elaborada de tela o de
                    cualquier otro material que tenga una duración cuando menos igual a
                    la del producto al que se aplique, cosida o adherida por un proceso
                    de termo fijación o similar que garantice su durabilidad, pudiendo
                    también estar bordada, impresa o estampada en el producto.</dd>

                  <dt>Etiqueta código QR</dt>
                  <dd>Aquella que utiliza un código bidimensional que almacena datos
                    codificados, y que tiene como destinatario a un consumidor final.
                    Este código podrá ser leído y descifrado mediante un lector óptico
                    que transmite datos de manera directa a un dispositivo electrónico.</dd>

                  <dt>Etiqueta temporal</dt>
                  <dd>Es aquella de cualquier material y de carácter removible.</dd>

                  <dt>Fabricante</dt>
                  <dd>Persona física o moral que produce los productos objeto de esta
                    norma.</dd>

                  <dt>Fibras recicladas</dt>
                  <dd>Este término se aplica, en textiles, a las fibras producidas
                    mediante procesos que utilizan como materia prima, materiales que ya
                    han cumplido un ciclo de vida diferente de fibra textil, por
                    ejemplo, tereftalato de polietileno-PET.</dd>

                  <dt>Fibras recuperadas</dt>
                  <dd>Este término se aplica a las fibras obtenidas mediante procesos
                    que utilizan como insumo materiales textiles, obtenidos de prendas
                    de vestir, recortes de tela u otros textiles, los cuales reutilizan
                    las fibras, para cumplir un ciclo de vida adicional.</dd>

                  <dt>Forro</dt>
                  <dd>Revestimiento de material textil confeccionado o diseñado para
                    llevarse en la parte interior del producto objeto de la norma de
                    manera total o parcial. No se considera como forro a aquellas
                    prendas de vestir de doble vista o reversibles.</dd>

                  <dt>Importador</dt>
                  <dd>Persona física o moral que importa productos objeto de esta norma.</dd>

                  <dt>Insumo</dt>
                  <dd>Materia prima susceptible de ser utilizada en la fabricación o
                    confección de productos objeto de esta norma, excluyendo aquellas
                    que se incorporen al producto y que no sean elaboradas a base de
                    textiles para efectos funcionales, tales como botones, cierres,
                    broches, etc.</dd>

                  <dt>Insumo de protección</dt>
                  <dd>Elemento destinado para ser utilizado en el interior o exterior de
                    cualquier producto objeto de esta norma con la finalidad de dar
                    mayor seguridad, cuidado y/o durabilidad a un producto, por ejemplo,
                    los aislamientos o rellenos, aplicaciones de plásticos entre otros.</dd>

                  <dt>Licenciatario</dt>
                  <dd>Propietario o titular de una marca que, ha ordenado su producción
                    total o parcial a un fabricante.</dd>

                  <dt>Lugar visible</dt>
                  <dd>Cualquier punto en el anverso o reverso de la prenda de vestir o
                    accesorio, salvo el interior de las mangas o piernas, siempre que la
                    etiqueta resulte visible por el solo hecho de colocar la prenda de
                    frente o vuelta, sin necesidad de descoser o desprender parte o todo
                    el forro u otros elementos de la prenda.</dd>

                  <dt>Masa</dt>
                  <dd>Magnitud física fundamental, referida a la cantidad de materia que
                    contiene un cuerpo, cuya unidad es el kilogramo y su símbolo es kg.</dd>

                  <dt>Ornamento</dt>
                  <dd>Elemento que sirve de adorno a un producto, debiendo estar
                    integrado al mismo. Puede ser o no de materia textil, exceptuando el
                    estampado.</dd>

                  <dt>País de origen</dt>
                  <dd>El lugar de manufactura, fabricación o elaboración del producto.</dd>

                  <dt>Prenda de vestir</dt>
                  <dd>Artículo confeccionado con textiles, que tiene como finalidad
                    cubrir, proteger o decorar personas o animales, excepto calzado.</dd>

                  <dt>Responsable del producto</dt>
                  <dd>En el caso de productos de origen nacional la persona física o
                    moral que sea fabricante, o licenciatario en caso de marcas
                    internacionales. En el caso de productos importados, persona física
                    o moral que sea el importador de dichos productos.</dd>

                  <dt>Ropa de casa</dt>
                  <dd>Artículos elaborados con fibras naturales o químicas o una mezcla
                    de éstas, que tienen un uso distinto a las prendas de vestir, y que
                    están diseñadas para cualquiera de las siguientes funciones:
                    protección, adorno o limpieza del hogar y establecimientos
                    comerciales o de servicio, como son: cortinas, toallas, alfombras,
                    mantas, cobertores, sábanas etc. mencionando de manera enunciativa
                    mas no limitativa.</dd>

                  <dt>Ropa de protección</dt>
                  <dd>Ropa incluyendo protectores que cubren o reemplazan la ropa
                    personal y que está diseñada para brindar protección contra uno o
                    más peligros.</dd>

                  <dt>Tapete</dt>
                  <dd>Revestimiento de material textil para cualquier superficie que no
                    requiere de servicio de instalación.</dd>

                  <dt>Textil</dt>
                  <dd>Producto elaborado a partir de fibras naturales y/o fibras
                    químicas o una mezcla de éstas, incluyéndose entre ellos, en forma
                    enunciativa mas no limitativa, hilados, hilos de coser, hilos de
                    bordar, estambres, telas en crudo y acabadas, tejidas y no tejidas,
                    casimires, pasamanerías (encajes, listones, bordados, elásticos), y
                    similares.</dd>

                  <dt>Textiles de protección</dt>
                  <dd>Productos textiles utilizados como barrera para protección de
                    personas o bienes.</dd>

                  <dt>Recortes de tela</dt>
                  <dd>Desperdicios de tela que se generan por el corte de las piezas a
                    confeccionar.</dd>

                  <dt>Pasamanería</dt>
                  <dd>Conjunto de objetos de decoración confeccionados a base de
                    cordones, borlas o galones. Es toda tela menor a 15 centímetros de
                    ancho, como cuerdas, cordones, elásticos, galones, listones, fleco,
                    espiguillas, soutache, cintas, tiras plisadas.</dd>

                  <dt>Textil técnico</dt>
                  <dd>Producto caracterizado para otorgar un beneficio de seguridad para
                    los usuarios, sin importar las características estéticas o
                    decorativas y está orientado a distintas aplicaciones en distintos
                    sectores</dd>

                </dl>
              </p>
            </div>
          </div>
        </blockquote>
        <div className='norm-info__questions'>
          <h2 className="norm-info__container__sub-title">Preguntas frecuentes</h2>
          <ul className="accordion norm-info__container__not-transform-ul" id="accordion">
            <li className="accordion-item">
              <div id="heading1">
                <button
                  className="accordion-button norm-info__container__accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse1"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  ¿Cuándo aplica la NOM-004-SE-2021?
                </button>
              </div>
              <div
                id="collapse1"
                className="accordion-collapse collapse show"
                aria-labelledby="heading1"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  Esta norma es aplicable para el etiquetado de productos textiles,
                  prendas de vestir, accesorios y ropa de casa.

                  <br /><br />
                  Aplica para todos aquellos productos que se vendan y distribuyan a
                  lo largo y ancho de la república Mexicana.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse2"
                  aria-expanded="false"
                  aria-controls="collapse2"
                >
                  ¿Qué dice la NOM-004-SE-2021?
                </button>
              </div>
              <div
                id="collapse2"
                className="accordion-collapse collapse"
                aria-labelledby="heading2"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  Esta norma tiene principalmente la función de informar por medio del
                  etiquetado la información de los insumos con los que fueron
                  elaborados los productos, las instrucciones de cuidado a seguir, los
                  tamaños o medidas y el responsable del producto.
                  <br /><br />
                  Para el cumplimiento de esta norma oficial mexicana es necesario
                  apoyarse con las siguientes normas:
                  <br /><a
                    href="https://www.sinec.gob.mx/SINEC/Vista/Normalizacion/DetalleNMX.xhtml?pidn=UDFsNUc4a1V6QVNJMWtOYlBhUExCdz09"
                    target="_blank"
                  >NMX-A-2076-INNTEX-2013</a>, Industria textil - Fibras químicas -
                  Nombres genéricos.
                  <br /><a
                    href="https://www.sinec.gob.mx/SINEC/Vista/Normalizacion/DetalleNMX.xhtml?pidn=N1JFUE1palVqeEhUN1RyV1k0MEdQdz09"
                    target="_blank"
                  >NMX-A-6938-INNTEX-2013</a>, Industria textil - Fibras naturales -
                  Nombres genéricos y definiciones.
                  <br /><a
                    href="https://www.sinec.gob.mx/SINEC/Vista/Normalizacion/DetalleNMX.xhtml?pidn=dDM2c2xlNnhzUkRuOFJESnJKdTBNdz09"
                    target="_blank"
                  >NMX-A-3758-INNTEX-2014</a>, Textiles código de generación de
                  etiquetas de cuidado con el uso de símbolos.
                  <br /><a
                    href="https://dof.gob.mx/nota_detalle.php?codigo=5146201&fecha=04/05/2010#gsc.tab=0:~:text=DE%20LA%20NORMA-,NMX%2DA%2D029%2DINNTEX%2D2010,-INDUSTRIA%20TEXTIL%2D"
                    target="_blank"
                  >NMX-A-029-INNTEX-2010</a>, Industria textil - Tejidos de calada -
                  Telas auto extinguibles - Especificaciones.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading3">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse3"
                  aria-expanded="false"
                  aria-controls="collapse3"
                >
                  ¿Cuáles son los diferentes tipos de productos que se mencionan en la
                  NOM-004-SE-2021?
                </button>
              </div>
              <div
                id="collapse3"
                className="accordion-collapse collapse"
                aria-labelledby="heading3"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  Productos textiles, prendas de vestir, accesorios y ropa de casa.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading4">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse4"
                  aria-expanded="false"
                  aria-controls="collapse4"
                >
                  ¿Cuándo entró en vigor la NOM-004-SE-2021?
                </button>
              </div>
              <div
                id="collapse4"
                className="accordion-collapse collapse"
                aria-labelledby="heading4"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  La norma
                  <a
                    href="https://www.dof.gob.mx/nota_detalle.php?codigo=5640655&fecha=14/01/2022#gsc.tab=0"
                    target="_blank"
                  >NOM-004-SE-2021 fue publicada</a>
                  en el DOF el día 14/01/2022 y de acuerdo al artículo transitorio
                  Primero, entró en vigor 365 días naturales después de su
                  publicación, es decir, entró en vigor el día 14/01/2023.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading5">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse5"
                  aria-expanded="false"
                  aria-controls="collapse5"
                >
                  ¿Qué cambios se le hicieron recientemente a la NOM-004-SE-2021?
                </button>
              </div>
              <div
                id="collapse5"
                className="accordion-collapse collapse"
                aria-labelledby="heading5"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  La norma NOM-004-SE-2021 a diferencia de la
                  <a
                    href="https://www.dof.gob.mx/nota_detalle.php?codigo=4912048&fecha=21/06/2006#gsc.tab=0"
                    target="_blank"
                  >NOM-004-SCFI-2006</a>
                  contempla sustanciales diferencias principalmente en los siguientes
                  puntos:
                  <br />Se hacen precisiones sobre la forma en declarar las fibras de
                  los insumos de los productos, así como las tolerancias permitidas.
                  <br />En las instrucciones de cuidado para las prendas de vestir, se
                  adicionan nuevas formas de declarar sobre el tipo de lavado,
                  blanqueado, secado, planchado.
                  <br />En temas del responsable del producto se vuelve obligatorio
                  declarar el RFC (registro federal de contribuyentes) en las prendas
                  de vestir.
                  <br />Adicionalmente, se indica que las prendas de vestir podrán
                  colocar códigos QR para brindar mayor información.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading6">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse6"
                  aria-expanded="false"
                  aria-controls="collapse6"
                >
                  ¿Quién emite la NOM-004-SE-2021?
                </button>
              </div>
              <div
                id="collapse6"
                className="accordion-collapse collapse"
                aria-labelledby="heading6"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  La NOM-004-SE-2021 fue emitida por la Secretaría de Economía, y para
                  ello trabajó el Comité Consultivo Nacional de Normalización de la
                  Secretaría de Economía.
                  <br /><br />
                  Cabe mencionar que para la emisión de la NOM-004-SE-2021 se tuvieron
                  que pasar por varias etapas.
                  <br /><br />
                  Primeramente el 28 de febrero del 2020 Comité Consultivo Nacional de
                  Normalización de la Secretaría de Economía aprobó la publicación del
                  <a
                    href="https://www.dof.gob.mx/nota_detalle.php?codigo=5604299&fecha=05/11/2020#gsc.tab=0"
                    target="_blank"
                  >Proyecto de Norma Oficial Mexicana PROY NOM-004-SE-2020</a>,
                  Información comercial-Etiquetado de productos textiles, prendas de
                  vestir, sus accesorios y ropa de casa la cual se realizó en el
                  Diario Oficial de la Federación el 05 de noviembre de 2020, con
                  objeto de que los interesados presentaran sus comentarios.
                  <br /><br />
                  Durante los siguientes 60 días naturales el proyecto estuvo a
                  disposición del público en general para su consulta. Dentro de ese
                  mismo plazo, los interesados presentaron comentarios sobre el
                  contenido del proyecto. Entre los principales comentarios
                  relacionados, encontramos los siguientes:
                  <br /><br />
                  <ul>
                    <li>■ Solicitudes para establecer
                      precisiones sobre el campo de aplicación, así como aquellos
                      productos que se encuentran excluidos.</li>
                    <li>■ También se solicitaron
                      modificaciones para las definiciones del proyecto de norma, así
                      como adición de algunas otras que permitieran mejor su
                      entendimiento.</li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading7">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse7"
                  aria-expanded="false"
                  aria-controls="collapse7"
                >
                  ¿Cómo saber si mi producto entra dentro de los requisitos de la
                  NOM-004-SE-2021?
                </button>
              </div>
              <div
                id="collapse7"
                className="accordion-collapse collapse"
                aria-labelledby="heading7"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  <a href="#productos-exentos-de-este-norma">Productos exentos de esta
                    norma</a>. Si el producto que vas a comercializar, o importar se
                  encuentra dentro de este campo de aplicación, entonces deberás de
                  cumplir con lo dispuesto en esta norma.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading8">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse8"
                  aria-expanded="false"
                  aria-controls="collapse8"
                >
                  ¿Que debe de llevar la etiqueta un producto con la NOM-004-SE-2021?
                </button>
              </div>
              <div
                id="collapse8"
                className="accordion-collapse collapse"
                aria-labelledby="heading8"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  De manera general, el etiquetado de productos textiles comprende los
                  siguientes campos:
                  <br />
                  <br />
                  <ul>
                    <li>■ Información del responsable del
                      producto. En el caso de un producto importado el responsable es
                      aquel que aparece en el pedimento de importación.</li>
                    <li>■ País de origen.</li>
                    <li>■ Composición de las fibras.</li>
                    <li>■ Instrucciones de cuidado.</li>
                    <li>■ Tallas de las prendas de vestir y
                      dimensiones o medidas para la ropa de casa y textiles.</li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading9">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse9"
                  aria-expanded="false"
                  aria-controls="collapse9"
                >
                  ¿Cómo puedo demostrar el cumplimiento de la NOM-004-SE-2021?
                </button>
              </div>
              <div
                id="collapse9"
                className="accordion-collapse collapse"
                aria-labelledby="heading9"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  Contactando a
                  <a href="https://www.cecsatrade.mx/" target="_blank">CECSA</a>, ya
                  que nuestros grupo de expertos en la materia te dirán cuáles son los
                  requisitos que debe de llevar tu producto en la etiqueta, de esa
                  manera, evitaras demoras, pérdidas de tiempo, pagos de almacenaje,
                  re etiquetado.
                  <br /><br />
                  Se revisará la etiqueta, y se procederá a hacer observaciones para
                  que se realicen modificaciones, o en su caso, si la etiqueta se
                  encuentra correcta se procederá a emitir la
                  <a
                    href="https://www.cecsatrade.mx/constancy"
                    target="_blank"
                  >Constancia de Inspección.</a>
                  <br /><br />
                  Lo que deberás hacer es presentar la Constancia de Inspección en la
                  aduana junto con el producto. Ambos deben de coincidir, de esa
                  manera, podrás cumplir con las regulaciones y restricciones no
                  arancelarias de etiquetado. Recuerda que la vigencia de la
                  Constancia de Inspección es de manera indefinida.
                  <br /><br />
                  La única manera en que la constancia pierda su vigencia es que:
                  <br />
                  <ul>
                    <li>■ Se actualice la Norma
                      NOM-004-SE-2021</li>
                    <li>■ Que la composición del producto
                      cambie.</li>
                    <li>■ Que se requieran actualizar las
                      instrucciones de cuidado.</li>
                    <li>■ Que el país de origen cambie.</li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading10">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse10"
                  aria-expanded="false"
                  aria-controls="collapse10"
                >
                  ¿Qué pasa si no se cumple con la NOM-004-SE-2021?
                </button>
              </div>
              <div
                id="collapse10"
                className="accordion-collapse collapse"
                aria-labelledby="heading10"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  De acuerdo a la Ley de la Infraestructura de la Calidad, el no
                  cumplimiento a las disposiciones establecidas en esta Ley, pudiera
                  ser:
                  <br />
                  <ul>
                    <li>■ Apercibimiento.</li>
                    <li>■ Multas.</li>
                    <li>■ Clausura temporal o definitiva,
                      que podrá ser parcial o total.</li>
                    <li>■ Arresto administrativo hasta por
                      treinta y seis horas.</li>
                    <li>■ Suspensión o prohibición de la
                      comercialización de bienes, productos y servicios, incluyendo la
                      inmovilización de los mismos para impedir su comercialización.</li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading11">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse11"
                  aria-expanded="false"
                  aria-controls="collapse11"
                >
                  ¿Cómo importar un producto con la NOM-004-SE-2021?
                </button>
              </div>
              <div
                id="collapse11"
                className="accordion-collapse collapse"
                aria-labelledby="heading11"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  Para poder cumplir con el etiquetado de la NOM-004-SE-2021 podrás
                  optar por cualquiera de las siguientes alternativas:
                  <br /><br />

                  <ul>
                    <li>■ Presentar en la aduana la
                      mercancía etiquetada y una copia de una constancia de inspección
                      emitida por CECSA.</li>
                    <li>■ Etiquetar la mercancía en un
                      almacén general de depósito, bajo el régimen de depósito fiscal.
                    </li>
                    <li>■ Etiquetar la mercancía en un
                      almacén particular. Esta alternativa requiere cumplir con los
                      siguientes requisitos: a) Estar inscritos y activos en el padrón
                      de importadores con una antigüedad no menor a 2 años y b) haber
                      importado al país mercancías con un valor equivalente en moneda
                      nacional a 100,000 dólares de los Estados Unidos de América, en
                      una o varias operaciones, durante los 12 meses anteriores a la
                      fecha en que se pretende hacer la importación.</li>
                  </ul>
                  <br /><br />
                  Para la alternativa 1 te sugerimos contactar a
                  <a href="https://www.cecsatrade.mx/" target="_blank">CECSA Trade</a>
                  antes de realizar tu importación y que podamos resolver todas tus
                  dudas previo a que la mercancía se encuentre en aduana.
                  <br />Para esta opción, será necesario que la mercancía ya se
                  encuentre etiquetada antes de la importación y que además cuentes
                  con una constancia de inspección emitida por CECSA.
                  <br /><br />
                  La alternativa 2 es para empresas que desean etiquetar sus productos
                  en México, pero no cuentan con una trayectoria como importadores.
                  Usualmente es la manera en la que las nuevas empresas dan
                  cumplimiento a las regulaciones y restricciones no arancelarias de
                  normas oficiales mexicanas de información comercial.
                  <br /><br />
                  La alternativa 3 es para aquellas empresas con una trayectoria de al
                  menos 2 años en el padrón de importadores y que cuentan con una o
                  varias operaciones que superan los 100,000 USD.
                  <br /><br />
                  Cabe mencionar que éste no es el único requisito para importar
                  productos comprendidos en la NOM-004-SE-2021 por lo que te
                  recomendamos revisar el tema con tu agente aduanal, ya que este tipo
                  de productos requiere un padrón de importadores especial.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading12">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse12"
                  aria-expanded="false"
                  aria-controls="collapse12"
                >
                  ¿Dónde puedo encontrar el texto de la NOM-004-SE-2021?
                </button>
              </div>
              <div
                id="collapse12"
                className="accordion-collapse collapse"
                aria-labelledby="heading12"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  <a
                    href="https://www.dof.gob.mx/nota_detalle.php?codigo=5640655&fecha=14/01/2022#gsc.tab=0"
                    target="_blank"
                  >Texto de la NOM-004-SE-2021 del DIARIO OFICIAL DE LA FEDERACIÓN.
                  </a>
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading13">
                <button
                  className="accordion-button norm-info__container__accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse13"
                  aria-expanded="false"
                  aria-controls="collapse13"
                >
                  ¿Qué hago si me detienen mi producto en la aduana y me piden cumplir
                  con la NOM-004-SE-2021?
                </button>
              </div>
              <div
                id="collapse13"
                className="accordion-collapse collapse"
                aria-labelledby="heading13"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  En primer lugar, deberás de solicitar la fracción arancelaria del
                  producto. Una vez que tengas la fracción arancelaria y corrobores
                  que le solicitan el cumplimiento de la NOM-004-SE-2021 deberás de
                  contactar a CECSA para que te podamos asesorar al respecto.
                  <br /><br />
                  Para cumplir con el etiquetado, podrás hacerlo en México o antes del
                  despacho aduanero. (Revisar las preguntas anteriores)
                  <br /><br />
                  Te recomendamos analizar los costos de cada situación y evaluarlos.
                  <br /><br />
                  Una vez que nos contactes, te brindaremos la asesoría y apoyo para
                  que puedas liberar tu producto de la aduana. El proceso será rápido,
                  cada minuto cuenta, por lo que te dejamos nuestro correo (<a
                    href="mailto:contacto@cecsatrade.mx"
                    target="_blank"
                  >contacto@cecsatrade.mx</a>) y número de contacto por whatsapp (<a
                    href="https://wa.me/message/YZR3TYWZRARDH1"
                  >56 5019 0728</a>)
                </div>
              </div>
            </li>
          </ul>

        </div>
        <ServiceSteps
          withNewsLetter={false}
        />
      </section>
    </>
  )
}

export default Nom003