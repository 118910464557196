/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { BenefictsAndWhoDifferent, ExcusiveAttention, JsonLd, NormInfo, References, ServiceSteps, ServicesToOneClick } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom003: React.FC = () => {

  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-003-SSA1-2006 - Pinturas';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "https://www.cecsatrade.mx/nom-003-ssa1-2006",
            "url": "https://www.cecsatrade.mx/nom-003-ssa1-2006",
            "name": "NOM-003-SSA1-2006",
            "description": "La presente Norma Oficial Mexicana tiene por objeto establecer los requisitos de información sanitaria y generales que para la venta y suministro de las pinturas, tintas, barnices, lacas y esmaltes, debe satisfacer el etiquetado de sus envases.",
            "isPartOf": {
              "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1"
            },
            "datePublished": "2023-08-17T08:08:40-06:00",
            "dateModified": "2023-08-17T08:43:03-06:00",
            "primaryImageOfPage": {
              "@id": "https://www.cecsatrade.mx/assets/003.svg"
            }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="Esta Norma Oficial Mexicana es de observancia obligatoria para las personas físicas y morales que se dediquen al proceso e importación de pinturas, tintas, barnices, lacas y esmaltes."
        />
      </Helmet>
      <section className='norm-info norm-info--without-margin'>
        <div className='sub-header'>
          <div className='sub-header__spacer'></div>
          <a className='sub-header__button' href="https://blog.cecsatrade.mx/" target='_blank' rel="noreferrer">Blog</a>
          <a
            className='sub-header__email'
            href="mailto:asesoria@cecsatrade.mx"
            target="_blank">
            <img loading="lazy" src="/img/mail.svg" alt="Mail" />
            |
            Mail de CECSA
          </a>
        </div>
        <h1 className='norm-info__title'>NOM-003-SSA1-2006  PINTURAS Y LACAS</h1>
        <div className='norm-info__header norm-info__header--003'>
          <button onClick={() => {
            const element = document.getElementById("benefits");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
          }}>Beneficios</button>
          {/* <button>Quiero cumplir con la NOM</button> */}
          <button onClick={() => {
            const element = document.getElementById("preguntas-frecuentes");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
            }
          }}>Preguntas frecuentes</button>
        </div>
        <div className='norm-info__image-container'>
          {/* <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-003-asset.svg" alt="NOM 003 Logo" /> */}
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Sé el primero en saber...</h2>
            <br />
            <p>
              La <b>norma de pinturas</b> tiene por objeto establecer los requisitos de información sanitaria y comercial del correcto etiquetado de envases <b>pinturas, lacas, barnices, tintas y esmaltes</b> para la venta y suministro de los mismos.
              <br />
              <br />
              Esta <b>Norma Oficial Mexicana</b> es de carácter obligatorio para todas aquellas personas que se dediquen al <b>proceso de importación</b> y fabricación de los productos de esta <b>NOM</b>.
            </p>
          </div>
        </div>
        <div className='norm-info__data-container'>
          <img className='norm-info__data-container__img' loading="lazy" src="/img/gotas.svg" alt="gotas" />
          <h2>Entrada en <span className='norm-info__data-container__animation-text'>vigor</span></h2>
          <br />
          <p>
            La <b>NOM-003-SSA1-2006, Salud ambiental. Requisitos sanitarios que debe satisfacer el etiquetado de pinturas, tintas, barnices, lacas y esmaltes</b> fue publicada en el <b>Diario Oficial de la Federación</b> EL DÍA 04/08/2008 Y ENTRÓ EN VIGOR 550 DÍAS DESPUÉS DE SU PUBLICACIÓN, ES DECIR EL 05/02/2010
          </p>
        </div>
        <div className='norm-info__aplication'>
          <div className='norm-info__aplication__title'>
            <h2>Aplicación</h2>
            <img className='norm-info__aplication__title__img' loading="lazy" src="/img/pintura.svg" alt="pintura" />
          </div>
          <p>
            Hecha para la importaciópn de <b>pinturas, tintas, barnices, lacas y esmaltes</b>, así como la fabricación para venta y distribución en Mexico,
          </p>
          <ExcusiveAttention />
        </div>
        <blockquote cite="https://www.dof.gob.mx/nota_detalle.php?codigo=5055685&fecha=04/08/2008#gsc.tab=0" style={{ margin: '0' }}>
          <div className="norm-info__003-definitions">
            <img className='norm-info__003-definitions__background-1' loading="lazy" src="/img/003_defintions_background-1.svg" alt="background 1" />
            <img className='norm-info__003-definitions__pintura-img' loading="lazy" src="/img/nom-003-asset.svg" alt="pintura" />
            {/* <div className='norm-info__003-definitions__background'></div> */}
            <h2 className="norm-info__003-definitions__title">DEFINICIONES</h2>
            <div className="norm-info__003-definitions__item">
              <span>Artículos escolares</span>
              <p>Todos aquellos que utilizan los niños en la escuela</p>
            </div>
            <div className="norm-info__003-definitions__item">
              <span>Artículos para uso doméstico</span>
              <p>Todo artículo que se encuentra dentro de una casa para fines utilitarios y que puede estar al alcance de los niños.</p>
            </div>
            <div className="norm-info__003-definitions__item">
              <span>Producto con base acuosa</span>
              <p>Término genérico aplicado a los productos que en su composición volátil contienen principalmente agua.</p>
            </div>
            <div className="norm-info__003-definitions__item">
              <span>Producto con base disolvente</span>
              <p>Término genérico aplicado a los productos que en su composición volátil contienen principalmente disolvente(s).</p>
            </div>
            <div className="norm-info__003-definitions__item">
              <span>Envase</span>
              <p>Cualquier recipiente o envoltura en el cual está contenido el producto, para su distribución o venta.</p>
            </div>
            <div className="norm-info__003-definitions__item">
              <span>Etiqueta</span>
              <p>Cualquier rótulo, marbete, inscripción, imagen u otra materia descriptiva o gráfica, escrita, impresa, estarcida, marcada, grabada en alto o bajo relieve, adherida, sobrepuesta o fijada al envase.</p>
            </div>
            <div className="norm-info__003-definitions__item">
              <span>Producto</span>
              <p>Todo material líquido, semilíquido o sólido, con o sin materia colorante, que forma una capa protectora al aplicarse sobre cualquier superficie.</p>
            </div>
            <div className="norm-info__003-definitions__item">
              <span>Producto con plomo</span>
              <p>Aquellos con un contenido de plomo total mayor a 600 ppm en la aprte no volátil del producto o en una película seca del mismo.</p>
            </div>
            <div className="norm-info__003-definitions__item">
              <span>Producto sin plomo</span>
              <p>Aquellos con un contenido de plomo total de hasta 600 ppm en la parte no volátil del producto o en una pleicula seca del mismo.</p>
            </div>
            <img className='norm-info__003-definitions__background-2' loading="lazy" src="/img/003_defintions_background-2.svg" alt="background 2" />
          </div>
          {/* <div className="norm-info__container__image-container">
            <div>
              <p className="norm-info__container__text">
                <h2 className="norm-info__container__sub-title">Definiciones</h2>

                <dl>

                  <dt>Artículos escolares</dt>
                  <dd>Todos aquellos que utilizan los niños en la escuela y que son susceptibles de chuparse, lamerse, masticarse, tomando en cuenta el comportamiento normal y predecible de los niños. </dd>

                  <dt>Artículos para uso doméstico</dt>
                  <dd>Todo artículo que se encuentra dentro de una casa para fines utilitarios y que puede estar al alcance de los niños.</dd>

                  <dt>Base acuosa, producto</dt>
                  <dd>Término genérico aplicado a los productos que en su composición volátil contienen principalmente agua.</dd>

                  <dt>Base disolvente, producto</dt>
                  <dd>Término genérico aplicado a los productos que en su composición volátil contienen principalmente disolvente(s). Siendo productos en los que su vehículo (resina) está disuelto en un disolvente o mezclas de ellos.</dd>

                  <dt>Base disolvente, producto</dt>
                  <dd>Término genérico aplicado a los productos que en su composición volátil contienen principalmente disolvente(s). Siendo productos en los que su vehículo (resina) está disuelto en un disolvente o mezclas de ellos.</dd>

                </dl>
              </p>
            </div>
            <img loading="lazy" className="norm-info__container__image-container__image" src="/img/003-image-1.svg" alt="NOM Logo" />
          </div>
          <p className="norm-info__container__text">
            <dl>

              <dt>Disolventes</dt>
              <dd>Líquido volátil que se utiliza principalmente para disminuir la viscosidad de los productos y favorecer el proceso de aplicación. </dd>

              <dt>Envase</dt>
              <dd>Cualquier recipiente o envoltura en el cual está contenido el producto, para su distribución o venta. </dd>

              <dt>Etiqueta</dt>
              <dd>Cualquier rótulo, marbete, inscripción, imagen u otra materia descriptiva o gráfica, escrita, impresa, estarcida, marcada, grabada en alto o bajo relieve, adherida, sobrepuesta o fijada al envase.</dd>

              <dt>Proceso</dt>
              <dd>Conjunto de actividades relativas a la obtención, elaboración, fabricación, preparación, conservación, mezclado, acondicionamiento, envasado, manipulación, transporte, distribución, almacenamiento y expendio o suministro al público de productos.</dd>

              <dt>Producto</dt>
              <dd>Todo aquel material líquido, semilíquido o sólido, con o sin materia colorante finamente distribuida en suspensión, la cual puede formar una película sólida protectora o depositarse, después de aplicarse sobre metal, madera, materiales de mampostería, papel, piel, tela, plástico, sobre capas anteriores de pintura seca, o cualquier otra superficie, competencia de esta Norma que son: pinturas, tintas, barnices, lacas y esmaltes. Entiéndase por tinta a "tinta para impresión". </dd>

            </dl>
          </p>
          <div className="norm-info__container__image-container">
            <img loading="lazy" className="norm-info__container__image-container__image" src="/img/003-image-2.svg" alt="NOM Logo" />
            <div>
              <dl>

                <dt>Producto</dt>
                <dd>Todo aquel material líquido, semilíquido o sólido, con o sin materia colorante finamente distribuida en suspensión, la cual puede formar una película sólida protectora o depositarse, después de aplicarse sobre metal, madera, materiales de mampostería, papel, piel, tela, plástico, sobre capas anteriores de pintura seca, o cualquier otra superficie, competencia de esta Norma que son: pinturas, tintas, barnices, lacas y esmaltes. Entiéndase por tinta a "tinta para impresión".</dd>

                <dt>Productos con plomo</dt>
                <dd>Aquéllos con un contenido de plomo total mayor a 600 ppm en la parte no volátil del producto o en una película seca del mismo. </dd>

                <dt>Productos sin plomo</dt>
                <dd>Aquéllos con un contenido de plomo total de hasta 600 ppm en la parte no volátil del producto o en una película seca del mismo.</dd>

                <dt>Tinta para impresión</dt>
                <dd>Material de composición viscosa o fluida, constituida por pigmentos dispersos en un vehículo fluido, usado en la impresión, por cualquier proceso y sobre cualquier soporte.</dd>

              </dl>
            </div>
          </div> */}
        </blockquote>
        {/* <h2 className="norm-info__container__sub-title">Referencias</h2>
        <p className="norm-info__container__text">
          Esta Norma se complementa con las siguientes normas oficiales mexicanas o las que las sustituyan: 2.1 NMX-Z-12/2-1987, Muestreo para la inspección por atributos-Parte 2. Métodos de muestreo, tablas y gráficas. 2.2 Modificación a la Norma Oficial Mexicana NOM-004-SSA1-1993, Salud ambiental. Limitaciones y requisitos sanitarios para el uso y comercialización de monóxido de plomo (litargirio), óxido rojo de plomo (minio) y del carbonato básico de plomo (albayalde). 2.3 NOM-050-SCFI-2004, Información comercial-Etiquetado general de productos.
        </p> */}
        <BenefictsAndWhoDifferent title={'CERTIFICA TUS PINTURAS'} text={'Contáctanos, nosotros te apoyamos en cada paso que debes realizar para cumplir conforme lo establecido de manera nacional en materia de comercial dentro de la industria.'} />
        <References
          items={[
            (
              <li>
                <img src="/img/list-item-003.svg" alt="email" />
                <b>NMX-Z-12/2-1987</b> Muestra de la inspección para atributos (parte 2)
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-003.svg" alt="email" />
                Modificación a la Norma Oficial Mexicana <b>NOM-004-SSA1-1993</b> Salud ambiental. Limitaciones y requisitos sanitarios.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-003.svg" alt="email" />
                <b>NOM-050-SCFI-2004</b> Información comercial - etiquetado general de productos
              </li>
            )
          ]}
        />
        <NormInfo
          DOFDate='04/08/2008'
          VigorDate='05/02/2010'
          responsible='SECRETARIA DE SALUD y COMITÉ CONSULTIVO NACIONAL DE NORMALIZACION DE CONTROL Y FOMENTO SANITARIO'
          margin={true}
        />
        <br />
        <br />
        <div className='norm-info__questions' id='preguntas-frecuentes'>
          <h2 className="norm-info__container__sub-title">Preguntas frecuentes</h2>
          <ul className="accordion norm-info__container__not-transform-ul" id="accordion">
            <li className="accordion-item">
              <div id="heading1">
                <button className="accordion-button norm-info__container__accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                  ¿Qué es la NOM 003 SSA1 2006?
                </button>
              </div>
              <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Pertenece a salud ambiental y establece los requisitos sanitarios que se debe cumplir en el etiquetado de pinturas, tintas, barnices, lacas y esmaltes.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                  ¿Cuáles son los diferentes tipos de productos que se mencionan en la NOM 003 SSA1 2006?
                </button>
              </div>
              <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordion">
                <div className="accordion-body">
                  En esta norma se hacen mención para: artículos escolares, artículos para uso doméstico, productos con plomo, productos sin plomo, tintas para impresiones entre otros.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading3">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                  ¿Qué cambios se le hicieron recientemente a la NOM 003 SSA1 2006?
                </button>
              </div>
              <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordion">
                <div className="accordion-body">
                  La norma fue publicada originalmente en el año 1993, sin embargo el 04 de noviembre del 2005 se presentó el anteproyecto de modificación a la NOM 003 SSA1 1993.
                  <br /><br />El 14 de agosto del 2006 se publicó el Proyecto de modificación para que dentro de los 60 días naturales el público en general presentara sus comentarios al comité consultivo nacional de normalización de regulación y fomento sanitario.<br /><br />El 28 de junio del 2007 el comité consultivo nacional de normalización de regulación y fomento sanitario aprobó la versión definitiva.<br /><br />El 24 de abril del 2008 se publicaron los comentarios recibidos.<br /><br />Entre los principales cambios de la NOM-003-SSA1-2006 vs la NOM-003-SSA1-1993 se encuentran: <br />Se agregaron definiciones específicas para esa norma como: artículos escolares, artículos para uso doméstico, base acuosa, base disolvente, disolvente, envase, etiquetas, proceso, producto, producto con plomo, producto sin plomo, tinta para impresión.<br />Además se agregaron símbolos y abreviaturas necesarias para interpretar los requisitos de la norma: mg, g, kg, ppm, Pb<br />Las leyendas del pictograma de advertencia del APÉNDICE NORMATIVO A y el METODO TEORICO PARA EL CÁLCULO DE CONTENIDO DE PLOMO CON BASE EN LA FORMULACIÓN
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading4">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                  ¿Quién emite la NOM 003 SSA1 2006?
                </button>
              </div>
              <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordion">
                <div className="accordion-body">
                  La norma NOM-003-SSA1-2006 fue emitida por la Secretaría de Salud (SSA1).Sin embargo para lograr su objetivo trabajaron en ella el Subcomité de Salud Ambiental, el Comité Consultivo Nacional de Normalización de Regulación y Fomento Sanitario.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading5">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                  ¿Quién emite la NOM 003 SSA1 2006?
                </button>
              </div>
              <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Apli ca en la importación de pinturas, tintas, barnices, lacas y esmaltes, así como para la fabricación para venta y distribución en México. Para la norma NOM-003-SSA1-2006 es muy importante calcular el contenido de plomo con base en la formulación. Por lo que para ello se podrá contar con resultados analíticos para la clasificación del producto por su contenido de plomo.El cálculo de contenido de plomo con base en la formulación se fundamenta en la información proporcionada por los proveedores de los diversos componentes con los que se formulan los productos objeto de esta Norma. La información del proveedor puede ser desde una declaración de primera parte hasta un certificado de análisis de tercera parte.
                </div >
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading6">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                  ¿Cómo saber si mi producto entra dentro de los requisitos de la NOM-003-SSA1-2006?
                </button>
              </div>
              <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordion">
                <div className="accordion-b o dy">
                  Te recomendamos  consultarlo con tu agente aduanal o con expertos en la materia. Pero por lo regular te dejamos un listado de fracciones en las cuales aplica esta norma: 3207.10.02, 3207.20.01, 3207.20.99, 3207.30.01, 3208.10.02, 3208.20.03, 3208.90.99, 3209.10.02, 3209.90.99, 3210.00.04, 3212.90.99, 3213.10.01, 3213.90.99, 3215.11.03, 3215.19.02, 3215.90.02, 3215.90.03, 3215.90.99 entre otras. De igual manera, sugerimos revisar de manera constante, ya que las fracciones arancelarias cambian con el paso del tiempo.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading8">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                  ¿Que debe de llevar la etiqueta un producto con la NOM-003-SSA1-2006?
                </button>
              </div>
              <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Una etiqueta que cumpla con la NOM-003-SSA1-2006  debe de contener los siguientes puntos: <br />
                  Dependiendo de la naturaleza del producto, deberá llevar alguna de las siguientes <br /><br />
                  <blockquote cite="https://www.dof.gob.mx/nota_detalle.php?codigo=5055685&fecha=04/08/2008#gsc.tab=0">
                    a) NO FUME Y NO INGIERA ALIMENTOS DURANTE SU APLICACIÓN
                    <br />b) ¡PRECAUCIÓN! PRODUCTO INFLAMABLE MANTÉNGALO APARTADO DE ALTAS TEMPERATURAS, CHISPAS Y FLAMAS
                    <br />c) PROHIBIDO UTILIZAR ESTE PRODUCTO EN LA ELABORACIÓN, ACABADO O IMPRESIÓN DE JUGUETES, OBJETOS SUSCEPTIBLES DE LLEVARSE A LA BOCA, DE ARTÍCULOS PARA USO DOMÉSTICO Y/O ESCOLARES USADOS POR NIÑOS, ASÍ COMO EN LA PINTURA DE INTERIORES DE CASAS HABITACIÓN, RESTAURANTES, ÁREAS DE JUEGOS INFANTILES, ESCUELAS Y GUARDERÍAS
                    <br />d) PROHIBIDA SU VENTA A MENORES DE EDAD;
                    <br />e) NO SE DEJE AL ALCANCE DE LOS NIÑOS;
                    <br />f) EN CASO DE INGESTIÓN, NO SE PROVOQUE EL VÓMITO. SOLICITE ATENCIÓN MÉDICA DE INMEDIATO. EVITE EL CONTACTO DIRECTO;
                    <br />g) APLIQUE EN ESPACIOS CON VENTILACIÓN ADECUADA;
                    <br />h) CONTIENE COMPUESTOS DE PLOMO, DISOLVENTES Y SUSTANCIAS TÓXICAS, CUYO CONTACTO O INHALACIÓN PROLONGADA O REITERADA ORIGINA GRAVES DAÑOS A LA SALUD;
                    <br />i) CIERRE BIEN EL ENVASE DESPUÉS DE CADA USO.
                    <br />j) 'CONTIENE COMPUESTOS DE PLOMO, CUYO CONTACTO ORIGINA GRAVES DAÑOS A LA SALUD'
                    <br />k) 'CONTIENE DISOLVENTES Y SUSTANCIAS TÓXICAS, CUYO CONTACTO O INHALACIÓN PROLONGADA O REITERADA ORIGINA GRAVES DAÑOS A LA SALUD;'
                    <br />l) EL USO DE ESTE PRODUCTO POR NIÑOS DEBE SER SUPERVISADO POR ADULTOS
                    <br />m) EN CASO DE INGESTIÓN, NO SE PROVOQUE EL VÓMITO. SOLICITE ATENCIÓN MÉDICA DE INMEDIATO
                  </blockquote>
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading9">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                  ¿Cómo importar un producto con la NOM 003 SSA1 2006?
                </button>
              </div>
              <div id="collapse9" className="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#accordion">
                <div className="accordion-body">
                  De acuerdo al ACUERDO por el que la Secretaría de Economía emite Reglas y criterios de carácter general en materia de comercio exterior este tipo de productos solamente se podrá importar bajo el amparo de una constancia de inspección o constancia de conformidad emitida por una unidad de inspección acreditada y aprobada en los términos de la Ley de la Infraestructura de la Calidad.
                  <br />Para poder importar un producto con la NOM-003-SSA1-2006 deberás etiquetar previo al despacho aduanero, es decir, en origen, con tu proveedor o en la misma aduana.
                </div >
              </div >
            </li >
            <li className="accordion-item">
              <div id="heading10">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                  ¿Qué pasa si no se cumple con la NOM 003 SSA1 2006?
                </button>
              </div>
              <div id="collapse10" className="accordion-collapse collapse" aria-labelledby="heading10" data-bs-parent="#accordion">
                <div className="accordion-body">
                  De a cuerdo a la Ley de la Infraestructura de la Calidad, el no cumplimiento a las disposiciones establecidas en esta Ley, pudiera ser: Apercibimiento, Multas, Clausura temporal o definitiva, que podrá ser parcial o total, Arresto administrativo hasta por treinta y seis horas, Suspensión o prohibición de la comercialización de bienes, productos y servicios, incluyendo la inmovilización de los mismos para impedir su comercialización
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading11">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                  ¿Qué hago si me detienen mi producto en la aduana y me piden cumplir con la NOM 003 SSA1 2006?
                </button>
              </div>
              <div id="collapse11" className="accordion-collapse collapse" aria-labelledby="heading11" data-bs-parent="#accordion">
                <div className=" accordion-body">
                  En primer lugar, deberás de solicitar la fracción aranc e laria del producto. Una vez que tengas la fracción arancelaria y corrobo r es que le solicitan el cumplimiento de la NOM-003-SSA1-2006, deberás de contactar a una Un i dad de Inspección par a obtener una Constancia de Inspección.Debido a que el producto no puede etiquetarse en México, deberás de etiquetar en la aduana o regresar el producto con tu proveedor para que sea etiquetado.
                  <br />Te recomendamos analizar los costos de cada situación y evaluarlos.
                  <br /><br />Una vez que nos contactes, te brindaremos la asesoría y apoyo para que puedas liberar tu producto de la aduana. El proceso será rápido, cada minuto cuenta, por lo que te dejamos nuestro correo (contacto@cecsatrade.mx) y número de contacto (56 5019 0728)
                </div>
              </div>
            </li>
            <li className=" accordion-item">
              <div id="heading12">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                  ¿Cómo mostrar el cumplimiento de la NOM 003 SSA1 2006?
                </button>
              </div>
              <div id="collapse12" className="accordion-collapse collapse" aria-labelledby="heading12" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Contactando a CECSA, ya que nuestros grupo de expertos en la materia te dirán cuales son los requisitos que debe de llevar tu producto en la etiqueta, de esa manera, evitarás demoras, pérdidas de tiempo, pagos de almacenaje, re etiquetado
                  <br /><br />CECSA revisará la etiqueta, y procederá a hacerte observaciones para que se realicen modificaciones.O en su caso, si la etiqueta se encuentra correcta procederá a emitir la Constancia de Inspección.
                  <br /><br />Lo que deberás hacer es presentar la Constancia de Inspección en la aduana junto con el producto. Ambos deben de coincidir, de esa manera, podrás cumplir con las regulaciones y restricciones no arancelarias de etiquetado.
                  <br /><br />Recuerda que la vigencia de la Constancia de Inspección es de manera indefinida.
                  <br /><br />La única manera en que la constancia pierda su vigencia es que:<br /><br />
                  - Se actualice la Norma NOM-003-SSA1-2006
                  <br />- Que el producto cambie el etiquetado
                  <br />- Que se agregue una diferente formulación al producto
                  <br />- Que cambie la razón social de importador Y en general que cambie cualquier aspecto de la presentación del producto
                </div>
              </div>
            </li>
          </ul>
        </div>
        <img className='norm-info__ellipse' src="/img/ellipse-blue.svg" alt="ellipse" />
        <ServicesToOneClick />
        <ServiceSteps
          withNewsLetter={true}
        />
      </section >
    </>
  )
}

export default Nom003