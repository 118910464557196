/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { JsonLd, NormInfo, ServiceSteps } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom142: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-173-SE-2021 - Jugos';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {

            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "https://www.cecsatrade.mx/nom-173-se-2021",
            "url": "https://www.cecsatrade.mx/nom-173-se-2021",
            "name": "NOM-173-SE-2021",
            "description": "Jugos, agua de coco, néctares, bebidas no alcohólicas con contenido de vegetal o fruta u hortaliza y bebidas saborizadas no alcohólicas preenvasadas-Denominaciones-Especificaciones-Información comercial y métodos de prueba.",
            "isPartOf": {
              "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1"
            },
            "datePublished": "2023-01-31T08:08:40-06:00",
            "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": {
              "@id": "https://www.cecsatrade.mx/assets/173.svg"
            }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="La presente Norma Oficial Mexicana tiene por objeto establecer las especificaciones de las denominaciones de producto y la información comercial que debe contener el etiquetado de los productos preenvasados destinados al consumidor final, de fabricación nacional o extranjera, que se comercialicen en territorio nacional con las denominaciones de jugo, agua de coco, néctar, bebidas no alcohólicas con un contenido de vegetal o fruta u hortaliza y bebidas saborizadas no alcohólicas haciendo referencia a sabores de vegetales o frutas u hortalizas."
        />
      </Helmet>
      <section className='norm-info'>
        <h1 className='norm-info__title'>NOM-173-SE-2021</h1>
        <h2>Jugos, agua de coco, néctares, bebidas no alcohólicas con contenido de vegetal o fruta uhortaliza y bebidas saborizadas no alcohólicas preenvasadas -Denominaciones-Especificaciones-Información comercial y métodosde prueba.</h2>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text'>
            <h2>Objetivo y campo de aplicación</h2>
            <p>
              La presente Norma Oficial Mexicana tiene por objeto establecer las especificaciones de las denominaciones de producto y la información comercial que debe contener el etiquetado de los productos preenvasados destinados al consumidor final, de fabricación nacional o extranjera, que se comercialicen en territorio nacional con las denominaciones de jugo, agua de coco, néctar, bebidas no alcohólicas con un contenido de vegetal o fruta u hortaliza y bebidas saborizadas no alcohólicas haciendo referencia a sabores de vegetales o frutas u hortalizas.
            </p>
          </div>
          <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-173.svg" alt="NOM 173 Logo" />
        </div>

        <div className="norm-info__container__image-container">
          <div>
            <p className="norm-info__container__text">

              <h2 className="norm-info__container__sub-title" id="no-aplica">No aplica a</h2>
              <p className="norm-info__container__text">
                <ol type="A">
                  <li>■ Café que requiera una preparación de percolación o extracción con agua y se comercialice como bebida no alcohólica preenvasada.</li>
                  <li>■ Productos que requieran de un modo de preparación a través de adicionar agua u otro líquido natural o transformado.</li>
                  <li>■ Los productos objeto de las siguientes Normas Oficiales Mexicanas, sus modificaciones o las que las sustituyan, conforme a lo establecido en las mismas:
                    <br />
                    <ul>
                      <li> <a href="https://www.dof.gob.mx/normasOficiales/4692/seeco/seeco.htm">NOM-155-SCFI-2012</a>, Leche - Denominaciones, especificaciones fisicoquímicas, información comercial y métodos de prueba, publicada en el Diario Oficial de la Federación el 3 de mayo de 2012.</li>
                      <li> <a href="https://dof.gob.mx/nota_detalle.php?codigo=5549317&fecha=31/01/2019">NOM-181-SCFI/SAGARPA-2018</a>, Yogurt - Denominación, especificaciones fisicoquímicas y microbiológicas, información comercial y métodos de prueba, publicada en el Diario Oficial de la Federación el 31 de enero de 2019.</li>
                    </ul>
                  </li>
                  <li>■ Productos de venta a granel o que se utilicen como materia prima para la elaboración de otros alimentos o bebidas no alcohólicas.</li>
                </ol>
              </p>
            </p>
          </div>
        </div>
        <div className="norm-info__container__image-container">
          <div>
            <p className="norm-info__container__text">
              <h2
                className="norm-info__container__sub-title"
                id="definiciones"
              >Definiciones</h2>

              <dl>

                <dt>Adulteración</dt>
                <dd>Producto cuya naturaleza y composición no corresponda a aquella con que se etiquete, anuncie, expenda, suministre o por no cumplir con las especificaciones descritas en esta Norma Oficial Mexicana, aun cuando haya sufrido un tratamiento que disimule dichas circunstancias o bien, que encubra defectos en su proceso o en la calidad sanitaria de las materias primas utilizadas en él.</dd>

                <dt>Agua de coco</dt>
                <dd>Líquido que se extrae directamente del fruto (Cocos nucifera L.) sin exprimir la pulpa.</dd>

                <dt>Agua de coco concentrada</dt>
                <dd>Es a la que se le ha eliminado físicamente el agua en una cantidad suficiente para elevar el nivel de grados Brix al menos en un 50 % más que el valor Brix establecido para el producto líquido obtenido y que ha sido sometido al tratamiento físico o a las condiciones de almacenamiento adecuadas que aseguren su conservación en el envase.</dd>

              </dl>
            </p>
          </div>
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/173-image-1.svg"
            alt="NOM Logo"
          />
        </div>
        <p className="norm-info__container__text">
          <dl>

            <dt>Agua de coco de concentrado</dt>
            <dd>Agua de coco elaborada a partir de agua de coco concentrada.</dd>

            <dt>Azúcares añadidos</dt>
            <dd>Azúcares libres agregados a los alimentos y a las bebidas no alcohólicas durante la elaboración industrial.</dd>

            <dt>Azúcares libres</dt>
            <dd>Monosacaridos y disacaridos disponibles, añadidos a los alimentos y a las bebidas no alcohólicas por el fabricante, más los azúcares que están presentes naturalmente en miel y jarabes de maíz de alta fructuosa, entre otros jarabes similares.</dd>

            <dt>Bebidas no alcohólicas con contenido de vegetal o fruta u hortaliza</dt>
            <dd>Aquellos líquidos naturales o transformados que proporcionan al organismo elementos para su nutrición y que contienen un porcentaje mayor o igual al 10% en su composición final de vegetales o fruta, agua de coco o coco u hortaliza, o la combinación de los mismos, así como uso de extractos naturales, incluyendo leguminosas como soya, arroz, entre otros del reino vegetal.</dd>

            <dt>Bebidas saborizadas no alcohólicas</dt>
            <dd>Aquellos líquidos naturales o transformados que proporcionan al organismo elementos para su nutrición y que contienen un porcentaje menor al 10% en su composición final de vegetales o fruta, agua de coco o coco, u hortaliza, o que no contengan ningún porcentaje de los mismos y hagan uso de extractos o saborizantes naturales o artificiales a cualquier vegetal o fruta, agua de coco o coco, u hortaliza, o la combinación de los mismos, incluyendo leguminosas como soya, arroz, entre otros del reino vegetal.</dd>

          </dl>
        </p>
        <div className="norm-info__container__image-container">
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/173-image-2.svg"
            alt="NOM Logo"
          />
          <div>
            <p className="norm-info__container__text">
              <dl>

                <dt>Edulcorantes</dt>
                <dd>Sustancias diferentes de los monosacáridos y de los disacáridos, que imparten un sabor dulce a los productos.
                  Fuente: ACUERDO por el que se determinan los aditivos y coadyuvantes en alimentos, bebidas y suplementos alimenticios, su uso y disposiciones sanitarias publicado en el Diario Oficial de la Federación el 16 de julio de 2012 y sus modificaciones.</dd>

                <dt>Frutas cítricas</dt>
                <dd>Frutas de la familia Rutaceae que se dañan con bajas temperaturas y se caracterizan porque tienen aceites y pigmentos en su cáscara.</dd>

                <dt>Frutas sanas</dt>
                <dd>Aquellas libres de enfermedades, heridas, pudriciones, daños producidos por insectos u otras plagas, libres de insectos vivos o muertos o sus larvas.</dd>

                <dt>Frutas o vegetales u hortalizas maduras</dt>
                <dd>Aquellas que han alcanzado el grado de desarrollo adecuado para su consumo (que están en su punto de sazón o madurez).</dd>

                <dt>Grados Brix</dt>
                <dd>Es el porcentaje de sólidos disueltos en un producto.</dd>

                <dt>Jugo</dt>
                <dd>Producto líquido sin fermentar, pero fermentable, obtenido al exprimir vegetales o frutas u hortalizas en buen estado, debidamente maduras y frescas o que se han mantenido en buen estado por procedimientos adecuados, inclusive por tratamientos de superficie aplicados después de la cosecha, clarificado o no, y sometido al tratamiento adecuado que asegura su conservación en el envase.</dd>

                <dt>Jugo de vegetales o frutas u hortalizas concentrado</dt>
                <dd>Es al que se les ha eliminado físicamente el agua en una cantidad suficiente para elevar el nivel de grados Brix al menos en un 50% más que el valor Brix establecido para el producto líquido obtenido al exprimir vegetales o frutas u hortalizas; para el caso de las frutas, deben ser sanas y maduras, y haber sido sometidas
                  al tratamiento físico o a las condiciones de almacenamiento adecuadas que aseguren su conservación en el envase.</dd>

                <dt>Jugo de vegetales o frutas u hortalizas de concentrados</dt>
                <dd>Jugo elaborado a partir de jugo concentrado de vegetales o frutas u hortalizas.</dd>

              </dl>
            </p>
          </div>
        </div>
        <NormInfo
          DOFDate='28/02/2022'
          VigorDate='27/08/2022'
          responsible='SECRETARÍA DE ECONOMIA'
        />
        <p className="norm-info__container__text">
          <h2
            className="norm-info__container__sub-title"
            id="referencias"
          >Referencias</h2>
          Esta Norma se complementa con las siguientes Normas Oficiales Mexicanas y
          Normas Mexicanas o las que las sustituyan:

          Esta Norma Oficial Mexicana se complementa con las siguientes Normas Oficiales Mexicanas y Normas Mexicanas vigentes, sus modificaciones, o aquellas que las sustituyan:<br />
          <ul>
            <li><a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5263188&fecha=10/08/2012">NOM-002-SCFI-2011</a> Productos preenvasados-Contenido neto-Tolerancias y métodos de verificación, publicada en el Diario Oficial de la Federación el 10 de agosto de 2012.</li>
            <li><a href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002">NOM-008-SCFI-2002</a> Sistema General de Unidades de Medida, publicada en el Diario Oficial de la Federación el 27 de noviembre de 2002.</li>
            <li><a href="https://www.dof.gob.mx/nota_detalle.php?codigo=4936602&fecha=06/11/2006">NOM-030-SCFI- 2006</a> Información comercial-Declaración de cantidad en la etiqueta-Especificaciones, publicada en el Diario Oficial de la Federación el 6 de noviembre de 2006.</li>
            <li><a href="https://www.cecsatrade.mx/nom-051-scfi-ssa1-2010">NOM-051-SCFI/SSA1-2010</a> Especificaciones generales de etiquetado para alimentos y bebidas no alcohólicas preenvasados-Información comercial y sanitaria, publicada en el Diario Oficial de la Federación el 5 de abril de 2010.</li>
            <li><a href="https://dof.gob.mx/nota_detalle.php?codigo=4890075&fecha=26/06/1996">NOM-086-SSA1-1994</a> Bienes y Servicios. Alimentos y bebidas no alcohólicas con modificaciones en su composición. Especificaciones nutrimentales, publicada en el Diario Oficial de la Federación el 26 de junio de 1996.</li>
            <li><a href="https://dof.gob.mx/nota_detalle.php?codigo=4901457&fecha=21/11/1997">NOM-130-SSA1-1995</a> Bienes y servicios. Alimentos envasados en recipientes de cierre hermético y sometidos a tratamiento térmico. Disposiciones y especificaciones sanitarias, publicada en el Diario Oficial de la Federación el 21 de noviembre de 1997.</li>
            <li><a href="https://dof.gob.mx/normasOficiales/4643/salud/salud.htm">NOM-218-SSA1-2011</a> Productos y servicios. Bebidas saborizadas no alcohólicas, sus congelados, productos concentrados para prepararlas y bebidas adicionadas con cafeína. Especificaciones y disposiciones sanitarias. Métodos de prueba, publicada en el Diario Oficial de la Federación el 10 de febrero de 2012.</li>
            <li><a href="https://www.dof.gob.mx/normasOficiales/3980/salud/salud.htm">NOM-251-SSA1-2009</a> Prácticas de higiene para el proceso de alimentos, bebidas o suplementos alimenticios, publicada en el Diario Oficial de la Federación el 1 de marzo de 2010.</li>
            <li><a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5095187&fecha=19/06/2009#gsc.tab=0">NMX-F-103-NORMEX-2009</a> Alimentos-Determinación de grados Brix en alimentos y bebidas, Método de ensayo (prueba). Declaratoria de vigencia publicada en el Diario Oficial de la Federación el 19 de junio de 2009.
              ACUERDO ACUERDO por el que se determinan los aditivos y coadyuvantes en alimentos, bebidas y suplementos alimenticios, su uso y disposiciones sanitarias, publicado en el Diario Oficial de la Federación el 16 de julio de 2012.</li>
          </ul>
        </p>
        <ServiceSteps
          withNewsLetter={false}
        />
      </section>
    </>
  )
}

export default Nom142