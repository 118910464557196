/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { JsonLd, NormInfo, ServiceSteps } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom142: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-187-SSA1/SCFI-2002 - Tortillas y masa';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org", "@type": "WebPage", "@id":
              "https://www.cecsatrade.mx/nom-187-ssa1-scfi-2002", "url":
              "https://www.cecsatrade.mx/nom-187-ssa1-scfi-2002", "name":
              "NOM-187-SSA1/SCFI-2002", "description": `Esta Norma Oficial Mexicana tiene
            por objeto establecer las especificaciones sanitarias que deben cumplir la
            masa, tortillas, tostadas, harinas preparadas para su elaboración y
            establecimientos donde se procesan. Asimismo, establece la información
            comercial que debe figurar en las etiquetas de los productos.`, "isPartOf": {
              "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1"
            }, "datePublished":
              "2023-01-31T08:08:40-06:00", "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": { "@id": "https://www.cecsatrade.mx/assets/187.svg" }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="Esta Norma Oficial Mexicana tiene por objeto establecer las
              especificaciones sanitarias que deben cumplir la masa, tortillas,
              tostadas, harinas preparadas para su elaboración y establecimientos donde
              se procesan. Asimismo, establece la información comercial que debe figurar
              en las etiquetas de los productos."
        />
      </Helmet>
      <section className='norm-info'>
        <h1 className='norm-info__title'>NOM-187-SSA1/SCFI-2002</h1>
        <h2>Productos y servicios. Masa, tortillas,
          tostadas y harinas preparadas para su elaboración y establecimientos donde
          se procesan. Especificaciones sanitarias. Información comercial. Métodos de
          prueba.</h2>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text'>
            <h2>Objetivo y campo de aplicación</h2>
            <p>
              Esta Norma Oficial Mexicana tiene por objeto establecer las
              especificaciones sanitarias que deben cumplir la masa, tortillas,
              tostadas, harinas preparadas para su elaboración y establecimientos donde
              se procesan. Asimismo, establece la información comercial que debe figurar
              en las etiquetas de los productos.
              <br />
              <br />
              <h2 className="norm-info__container__sub-title" id="no-aplica">No aplica a</h2>
              Quedan excluidas las botanas.
              <br />
            </p>
          </div>
          <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-187.svg" alt="NOM 187 Logo" />
        </div>
        <div className="norm-info__container__image-container">
          <div>
            <p className="norm-info__container__text">
              <h2
                className="norm-info__container__sub-title"
                id="definiciones"
              >Definiciones</h2>

              <dl>

                <dt>Aditivos para alimentos</dt>
                <dd>Sustancias que se adicionan directamente a los productos,
                  durante su elaboración para proporcionar o intensificar aroma,
                  color o sabor; para mejorar su estabilidad o para su conservación,
                  entre otras funciones.</dd>

                <dt>Alimentos y bebidas no alcohólicas con modificaciones en su
                  composición</dt>
                <dd>Aquéllos a los que se les disminuyen, eliminan o adicionan uno o
                  más de sus nutrimentos, tales como hidratos de carbono, proteínas,
                  lípidos, vitaminas, minerales o fibras dietéticas.</dd>

                <dt>Aflatoxinas</dt>
                <dd>Metabolitos secundarios producidos por varios mohos, cuya
                  estructura química es heterocíclica, pertenecientes al grupo de
                  las bisfurano cumarinas. Poseen toxicidad aguda y crónica, así
                  como efectos mutagénicos y carcinogénicos en animales y el hombre.</dd>

                <dt>Bitácora o registro</dt>
                <dd>Documento controlado que provee evidencia objetiva y auditable
                  de las actividades ejecutadas o resultados obtenidos durante el
                  proceso del producto y su análisis.</dd>

                <dt>Botana</dt>
                <dd>Productos de pasta de harinas, de cereales, leguminosas,
                  tubérculos o féculas; así como de granos, frutas, frutos, semillas
                  o leguminosas con o sin cáscara o cutícula, tubérculos; productos
                  nixtamalizados y piel de cerdo, que pueden estar fritos,
                  horneados, explotados, cubiertos, extruidos o tostados;
                  adicionados o no con sal y otros ingredientes opcionales y
                  aditivos para alimentos.</dd>

              </dl>
            </p>
          </div>
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/187-image-1.svg"
            alt="NOM Logo"
          />
        </div>
        <p className="norm-info__container__text">
          <dl>

            <dt>Buenas prácticas de fabricación</dt>
            <dd>Conjunto de lineamientos y actividades relacionadas entre sí,
              destinadas a garantizar que los productos tengan y mantengan las
              especificaciones sanitarias requeridas para su uso o consumo. En
              particular en el caso de los aditivos se refiere a la cantidad mínima
              necesaria para lograr el efecto deseado.</dd>

            <dt>Coadyuvante de elaboración</dt>
            <dd>Sustancia o materia, excluidos aparatos, utensilios y los aditivos,
              que no se consume como ingrediente alimenticio por sí misma, y se
              emplea intencionalmente en la elaboración de materias primas,
              alimentos o sus ingredientes, para lograr una finalidad tecnológica
              durante el tratamiento o la elaboración, que puede dar lugar a la
              presencia no intencionada, pero inevitable, de residuos o derivados en
              el producto final.</dd>

            <dt>Consumidor</dt>
            <dd>Persona física o moral que adquiere o disfruta como destinatario
              final productos alimenticios y bebidas no alcohólicas preenvasados.</dd>

            <dt>Embalaje</dt>
            <dd>Material que envuelve, contiene o protege debidamente a los envases
              primarios, secundarios, múltiples o colectivos, que facilita y resiste
              las operaciones de almacenamiento y transporte, no destinado para su
              venta al consumidor en dicha presentación.</dd>

            <dt>Envase colectivo</dt>
            <dd>Recipiente o envoltura en el que se encuentran contenidos dos o más
              variedades de productos preenvasados, destinados para su venta al
              consumidor en dicha presentación.</dd>

            <dt>Envase múltiple</dt>
            <dd>Recipiente o envoltura en el que se encuentran contenidos dos o más
              de productos preenvasados, destinados para su venta al consumidor en
              dicha presentación.</dd>

            <dt>Envase primario</dt>
            <dd>Recipiente destinado a contener un producto y que entra en contacto
              con el mismo.</dd>

            <dt>Envase secundario</dt>
            <dd>Al que contiene al envase primario de manera individual.</dd>

            <dt>Etiqueta</dt>
            <dd>Marbete, rótulo, inscripción, marca, imagen gráfica u otra forma
              descriptiva que se haya escrito, impreso, estarcido, marcado, en
              relieve o en hueco, grabado, adherido, precintado o anexado al empaque
              o envase del producto.</dd>

          </dl>
        </p>
        <div className="norm-info__container__image-container">
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/187-image-2.svg"
            alt="NOM Logo"
          />
          <div>
            <p className="norm-info__container__text">
              <dl>

                <dt>Establecimiento</dt>
                <dd>A los locales y sus instalaciones, sus dependencias y anexos
                  cubiertos o descubiertos, sean fijos o móviles, en los que se
                  desarrolla el proceso de los productos, actividades y servicios
                  objeto de esta norma, tales como: molinos de nixtamal,
                  tortillerías, frituras de tostadas y harinas para prepararlas.</dd>

                <dt>Fecha de caducidad</dt>
                <dd>Fecha límite en que se considera que un producto preenvasado
                  almacenado en las condiciones sugeridas por el fabricante, reduce
                  o elimina las características sanitarias que debe reunir para su
                  consumo. Después de esta fecha no debe comercializarse ni
                  consumirse.</dd>

                <dt>Freír</dt>
                <dd>Operación que consiste en sumergir total o parcialmente un
                  producto en aceite o grasa comestible caliente, a una temperatura
                  tal que permita alcanzar las características sensoriales deseadas.</dd>

                <dt>Harina de maíz nixtamalizado</dt>
                <dd>Producto deshidratado que se obtiene de la molienda de los
                  granos de maíz nixtamalizado.</dd>

                <dt>Harina integral</dt>
                <dd>Producto obtenido de la molienda del grano de cereal que
                  conserva su cáscara y germen.</dd>

              </dl>
            </p>
          </div>
        </div>

        <p className="norm-info__container__text">
          <dl>

            <dt>Harina o harina de trigo</dt>
            <dd>A la obtenida de la molienda del grano de trigo maduro, entero,
              quebrado, sano y seco del género Triticum, L.; de las especies de T.
              vulgare, T. compactum y T. durum o mezclas de éstas, limpio, sano en
              el que se elimina gran parte del salvado y germen, hasta obtener una
              harina de finura adecuada.</dd>

            <dt>Harina preparada para elaborar masa</dt>
            <dd>Tortillas o tostadas, al producto resultante de la mezcla de harina
              de trigo o de maíz nixtamalizado u otros cereales integrales o no, con
              ingredientes opcionales y aditivos para alimentos, y que se prepara
              conforme a las instrucciones del fabricante.</dd>

            <dt>Ingredientes opcionales</dt>
            <dd>Los que se pueden adicionar a la masa, tales como: chiles,
              condimentos, especias, harinas de cereales o leguminosas.</dd>

            <dt>Inocuo</dt>
            <dd>Que no causa daño a la salud.</dd>

            <dt>Límite máximo</dt>
            <dd>Cantidad establecida de aditivos, microorganismos, parásitos,
              materia extraña, plaguicidas, radionúclidos, biotoxinas, residuos de
              medicamentos, metales pesados y metaloides, entre otros, que no se
              deben exceder en un alimento, bebida o materia prima.</dd>

            <dt>Lote</dt>
            <dd>Cantidad de un producto elaborado en un mismo ciclo, integrado por
              unidades homogéneas.</dd>

            <dt>Maíz nixtamalizado o nixtamal</dt>
            <dd>Maíz que ha sido sometido a cocción parcial con agua en presencia de
              hidróxido de calcio (cal, óxido de calcio).</dd>

            <dt>Masa</dt>
            <dd>Producto obtenido de la molienda húmeda de granos de maíz
              nixtamalizado o pasta que se forma a partir de harina de maíz
              nixtamalizado, harina de trigo, harinas integrales o sus combinaciones
              y agua. Pudiendo estar mezclada con ingredientes opcionales y aditivos
              permitidos para alimentos.</dd>

            <dt>Materia extraña</dt>
            <dd>Material orgánico o inorgánico que se presenta en el producto por
              contaminación.</dd>

            <dt>Metal pesado y metaloide</dt>
            <dd>Elementos químicos que tienen un peso atómico entre 63 y 200 y una
              gravedad específica mayor de 4,0; que por su naturaleza presentan una
              gran reactividad y que dependiendo de la concentración, la forma
              química o su acumulación en el organismo pueden ocasionar efectos
              indeseables en el metabolismo.</dd>

            <dt>Métodos de prueba</dt>
            <dd>Procedimiento técnico utilizado para la determinación de parámetros
              o características de un producto, proceso o servicio.</dd>

          </dl>
        </p>
        <div className="norm-info__container__image-container">
          <div>
            <p className="norm-info__container__text">
              <dl>

                <dt>Plaguicida</dt>
                <dd>Sustancia o mezcla de sustancias que se destina a controlar
                  cualquier plaga, incluidos los vectores que transmiten las
                  enfermedades humanas y de animales, las especies no deseadas que
                  causen perjuicio o que interfieran en el proceso de los productos.</dd>

                <dt>Proceso</dt>
                <dd>Conjunto de actividades relativas a la obtención, elaboración,
                  fabricación, preparación, conservación, mezclado,
                  acondicionamiento, envasado, manipulación, transporte,
                  distribución, almacenamiento y expendio o suministro al público de
                  productos. Productos a granel, al producto que debe pesarse,
                  medirse o contarse en presencia del consumidor por no encontrarse
                  preenvasado al momento de su venta.</dd>

                <dt>Producto preenvasa</dt>
                <dd>Producto que cuando es colocado en un envase de cualquier
                  naturaleza, no se encuentra presente el consumidor y la cantidad
                  de producto contenido en él no puede ser alterada, a menos que el
                  envase sea abierto o modificado perceptiblemente.</dd>

                <dt>Tortilla</dt>
                <dd>Producto elaborado con masa que puede ser mezclada con
                  ingredientes opcionales, sometida a cocción.</dd>

                <dt>Tostada</dt>
                <dd>Producto elaborado a partir de tortilla o masa que puede ser
                  mezclada con ingredientes opcionales, sometido a un proceso de
                  horneado, freído, deshidratado o cualquier otro, hasta obtener una
                  consistencia rígida y crujiente.</dd>

              </dl>
            </p>
          </div>
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/187-image-3.svg"
            alt="NOM Logo"
          />
        </div>
        <NormInfo
          DOFDate='---'
          VigorDate='---'
          responsible='SECRETARIA DE SALUD y SECRETARIA DE ECONOMIA'
        />
        <p className="norm-info__container__text">
          <h2
            className="norm-info__container__sub-title"
            id="referencias"
          >Referencias</h2>
          Esta Norma se complementa con las siguientes Normas Oficiales Mexicanas y
          Normas Mexicanas o las que las sustituyan:
          <ul>
            <li><a
              href="https://dof.gob.mx/nota_detalle.php?codigo=4757142&fecha=09/07/1993#gsc.tab=0"
            >NOM-030-SCFI-1993</a>, Información comercial - Declaración de
              cantidad en la etiqueta - Especificaciones.
            </li>
            <li><a
              href="https://dof.gob.mx/nota_detalle.php?codigo=5531604&fecha=16/07/2018#gsc.tab=0"
            >NOM-040-SSA1-1993</a>, Bienes y servicios. Sal yodada y sal yodada
              fluorurada. Especificaciones sanitarias.</li>
            <li><a
              href="https://www.dof.gob.mx/nota_detalle.php?codigo=4867159&fecha=24/01/1996#gsc.tab=0"
            >NOM-050-SCFI-1994</a>, Información comercial. Disposiciones generales
              para productos.
            </li>
            <li><a
              href="https://dof.gob.mx/nota_detalle.php?codigo=4890075&fecha=26/06/1996"
            >NOM-086-SSA1-1994</a>, Bienes y servicios. Alimentos y bebidas no
              alcohólicas con modificaciones en su composición. Especificaciones
              nutrimentales.
            </li>
            <li><a
              href="https://dof.gob.mx/nota_detalle.php?codigo=4883170&fecha=16/10/1995"
            >NOM-110-SSA1-1994</a>, Bienes y servicios. Preparación y dilución de
              muestras de alimentos para su análisis microbiológico.
            </li>
            <li><a
              href="https://www.sinec.gob.mx/SINEC/Vista/Normalizacion/DetalleNorma.xhtml?pidn=NTJXNkVvTUx5OFpGb2piNzU1cWRPZz09"
            >NOM-113-SSA1-1994</a>, Bienes y servicios. Método para la cuenta de
              microorganismos coliformes totales en placa.</li>
            <li><a
              href="https://dof.gob.mx/nota_detalle.php?codigo=4869827&fecha=23/02/1995#gsc.tab=0"
            >NOM-117-SSA1-1994</a>, Bienes y servicios. Método de prueba para la
              determinación de cadmio, arsénico, plomo, estaño, cobre, fierro, zinc
              y mercurio en alimentos, agua potable y agua purificada por
              espectrometría de absorción atómica.
            </li>
            <li><a
              href="https://www.dof.gob.mx/nota_detalle.php?codigo=4880184&fecha=28/08/1995#gsc.tab=0"
            >NOM-120-SSA1-1994</a>, Bienes y servicios. Prácticas de higiene y
              sanidad en la elaboración de alimentos y bebidas no alcohólicas y
              alcohólicas.
            </li>
            <li><a
              href="https://www.dof.gob.mx/nota_detalle.php?codigo=2063863&fecha=31/12/1969#gsc.tab=0"
            >NOM-127-SSA1-1994</a>, Salud ambiental, agua para uso y consumo
              humano - Límites permisibles de calidad y tratamientos a que debe
              someterse el agua para su potabilización</li>
            <li><a
              href="https://dof.gob.mx/nota_detalle.php?codigo=4891221&fecha=15/08/1997#gsc.tab=0"
            >NOM-147-SSA1-1996</a>, Bienes y servicios. Cereales y sus productos.
              Harinas de cereales, sémolas o semolinas. Alimentos a base de
              cereales, de semillas comestibles, harinas, sémolas o semolinas o sus
              mezclas. Productos de panificación. Disposiciones y especificaciones
              sanitarias y nutrimentales.
            </li>
            <li><a
              href="https://www.dof.gob.mx/nota_detalle.php?codigo=719385&fecha=15/10/2002#gsc.tab=0"
            >NOM-188-SSA1-2002</a>, Productos y servicios. Control de aflatoxinas
              en cereales para consumo humano y animal. Especificaciones sanitarias.
            </li>
          </ul>
        </p>
        <ServiceSteps
          withNewsLetter={false}
        />
      </section>
    </>
  )
}

export default Nom142